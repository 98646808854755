import { Box, Typography } from "@mui/material";

interface Props {
  highlightHeader: string[];
  highlightInfo: string[];
  isPurchase?: boolean;
  bgColor?: string;
}

const PDFHighlight = ({
  highlightHeader,
  highlightInfo,
  isPurchase,
  bgColor,
}: Props) => {
  return (
    <Box
      px={1}
      py={0.5}
      bgcolor={isPurchase ? "#FCE8E8" : bgColor ? bgColor : "#ECF6FD"}
      borderRadius={1}
    >
      {highlightHeader.map((name, index) => (
        <Box sx={{ display: "flex", gap: 2 }} key={index}>
          <Typography
            sx={{
              fontSize: 11,
              lineHeight: "20px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 0.55,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: 11,
              lineHeight: "20px",
              letterSpacing: "0.15px",
              flex: 1,
              width: "100%",
              wordBreak: "break-word",
            }}
          >
            {highlightInfo[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PDFHighlight;
