import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QuotationHeader from "../../../components/Form/Sales/Quotation/Header";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import { IQuotation } from "../../../types/Sales/quotation";
import CustomerInfoForm from "../../../components/Form/CustomerInfo/CustomerInfoForm";
import PriceVatType from "../../../components/Form/Sales/PriceVatType";
import SalesFooter from "../../../components/Form/Sales/Footer";
import {
  quotationSchema,
  quotationValidation,
} from "../../../components/Form/Sales/Quotation/schema";
import { Box, CircularProgress, Stack } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { EntityTypeEnum } from "../../../generated/creatable";
import {
  ActivityType,
  QuotationCreateInput,
  QuotationQuery,
  QuotationUpdateInput,
  SalesActivityLogDocumentType,
  SalesDocumentType,
  Status,
  useQuotationCreateMutation,
  useQuotationQuery,
  useQuotationUpdateMutation,
  useSalesDocumentNextStatusMutation,
} from "../../../generated/sales";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import { useSnackbar } from "notistack";
import {
  quotationCreatePayloadFormatter,
  quotationQueryFormatter,
  quotationUpdatePayloadFormatter,
} from "../../../utils/Formatter/Quotation";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import AcceptForm from "../../../components/Form/Sales/Quotation/Accept";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    reset,
    resetField,
  } = useForm<IQuotation>({
    defaultValues: quotationSchema,
    resolver: yupResolver(quotationValidation),
    mode: "onChange",
  });

  const { salesActivityLogCreate } = useActivityLogCreate();

  const createdBy = useWatch({ control, name: "created_by" });
  const related_user_list = useWatch({ control, name: "related_user_list" });

  useRoleSelfPermission(createdBy, related_user_list);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch } =
    useQuotationQuery<QuotationQuery>(
      graphQLClientWithHeaderSales,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutateAsync: create } =
    useQuotationCreateMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: async ({ quotationCreate }) => {
        if (quotationCreate) {
          if (quotationCreate.sub_status === "wait_approve") {
            updateStatus({
              documentInput: {
                reference_document_type: SalesDocumentType.Quotation,
                unique_id: quotationCreate.unique_id,
              },
            });
          }
          navigate(`/sales/quotation/${quotationCreate?.unique_id}`);
          enqueueSnackbar("สร้างใบเสนอราคาสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบเสนอราคาไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: update } =
    useQuotationUpdateMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบเสนอราคาสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบเสนอราคาไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    useSalesDocumentNextStatusMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: () => {
        if (id) {
          refetch();
        }
      },
    });

  useEffect(() => {
    if (isSuccess) {
      const { quotation } = data;

      const getQuotationData = async () => {
        const quotationType = quotation as IQuotation;
        const formattedQuotation = await quotationQueryFormatter(quotationType);
        reset(formattedQuotation);
      };
      getQuotationData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      const { copied_id, copied_unique_id, ...otherState } = state;
      reset(otherState);
    }
  }, [reset, state]);

  useEffect(() => {
    if (!isLoading && id && data?.quotation?.aggrid_status !== "draft") {
      setDisabled(true);
    }
  }, [data?.quotation?.aggrid_status, id, isLoading]);

  const onQuotationCreate = async (data: IQuotation, status: string) => {
    try {
      if (data) {
        const payload = quotationCreatePayloadFormatter(
          data,
          status
        ) as QuotationCreateInput;
        const { quotationCreate } = await create({
          createInput: payload,
        });

        if (status === "wait_approve") {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Create,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationCreate.id,
              activity_detail: {},
            });
            if (state && state.copied_unique_id) {
              await salesActivityLogCreate({
                activity_type: ActivityType.Copy,
                document_type: SalesActivityLogDocumentType.Quotation,
                reference_id: quotationCreate.id,
                activity_detail: {
                  copied_from: {
                    id: state.copied_id,
                    unique_id: state.copied_unique_id,
                  },
                  copied_to: {
                    id: quotationCreate.id,
                    unique_id: quotationCreate.unique_id,
                  },
                },
              });
            }
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationCreate.id,
              activity_detail: {
                curr_status: Status.WaitApprove,
              },
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Create,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationCreate.id,
              activity_detail: {},
            });

            if (state && state.copied_unique_id) {
              await salesActivityLogCreate({
                activity_type: ActivityType.Copy,
                document_type: SalesActivityLogDocumentType.Quotation,
                reference_id: quotationCreate.id,
                activity_detail: {
                  copied_from: {
                    id: state.copied_id,
                    unique_id: state.copied_unique_id,
                  },
                  copied_to: {
                    id: quotationCreate.id,
                    unique_id: quotationCreate.unique_id,
                  },
                },
              });
            }
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationCreate.id,
              activity_detail: {
                curr_status: Status.Draft,
              },
            });
          } catch (err) {
            console.log(err);
          }
        }
      }
    } catch (err) {}
  };

  const onQuotationUpdate = async (data: IQuotation, status: string) => {
    try {
      if (data) {
        const payload = (await quotationUpdatePayloadFormatter(
          data,
          status
        )) as QuotationUpdateInput;
        setSnackbarMessage("แก้ไข");
        const { quotationUpdate } = await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        const changedData = Object.keys(dirtyFields);
        if (changedData.length > 0) {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Edit,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationUpdate.id,
              activity_detail: {},
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (err) {}
  };

  const onQuotationSendApprove = async (data: IQuotation) => {
    try {
      if (data) {
        const payload = (await quotationUpdatePayloadFormatter(
          data,
          "wait_approve"
        )) as QuotationUpdateInput;

        if (!id) {
          onQuotationCreate(data, "wait_approve");
        } else {
          setSnackbarMessage("ส่งอนุมัติ");
          const { quotationUpdate } = await update({
            uniqueInput: {
              unique_id: id,
            },
            updateInput: payload,
          });
          updateStatus({
            documentInput: {
              reference_document_type: SalesDocumentType.Quotation,
              unique_id: data.unique_id,
            },
          });
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.Quotation,
              reference_id: quotationUpdate.id,
              activity_detail: {
                curr_status: Status.WaitApprove,
              },
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (err) {}
  };

  const onQuotationNotApprove = async (data: IQuotation) => {
    try {
      const payload = (await quotationUpdatePayloadFormatter(
        data,
        "wait_approve",
        true
      )) as QuotationUpdateInput;
      setSnackbarMessage("ไม่อนุมัติ");
      const { quotationUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.Quotation,
          reference_id: quotationUpdate.id,
          activity_detail: {
            curr_status: Status.NotApproved,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) {}
  };

  const onQuotationApprove = async (data: IQuotation) => {
    try {
      const payload = (await quotationUpdatePayloadFormatter(
        data,
        "wait_accept"
      )) as QuotationUpdateInput;
      setSnackbarMessage("อนุมัติ");
      const { quotationUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.Quotation,
          unique_id: data.unique_id,
        },
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.Quotation,
          reference_id: quotationUpdate.id,
          activity_detail: {
            curr_status: Status.WaitAccept,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) {}
  };

  const onQuotationAcceptHandler = async () => {
    try {
      const data = getValues();
      const payload = (await quotationUpdatePayloadFormatter(
        data,
        "accepted"
      )) as QuotationUpdateInput;
      setSnackbarMessage("ตอบรับ");
      const { quotationUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.Quotation,
          unique_id: data.unique_id,
        },
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.Quotation,
          reference_id: quotationUpdate.id,
          activity_detail: {
            curr_status: Status.Accepted,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) {}
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onQuotationEditHandler = async (data: IQuotation) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    await onQuotationUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const renderButton = () => {
    switch (data?.quotation?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) => onQuotationUpdate(data, "draft"))}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onQuotationSendApprove)}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onQuotationEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onQuotationNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onQuotationApprove)}
                disabled={isChanging}
              />
            </Stack>
          );
        }

      case "not_approved":
      case "wait_accept":
      case "accepted":
      case "cancelled":
      case "finished":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onQuotationEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) => onQuotationCreate(data, "draft"))}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onQuotationSendApprove)}
            />
          </Stack>
        );
    }
  };

  useSalesError(errors);

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <QuotationHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        refetch={refetch}
        editClickHandler={editClickHandler}
        onQuotationAcceptHandler={onQuotationAcceptHandler}
      />
      <CustomerInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.Quotation}
      />
      <PriceVatType control={control} errors={errors} disabled={disabled} />
      <SalesItemList
        control={control}
        errors={errors}
        disabled={disabled}
        setValue={setValue}
        documentType={EntityTypeEnum.Quotation}
        getValues={getValues}
      />
      <SalesFooter
        control={control}
        errors={errors}
        disabled={disabled}
        setValue={setValue}
        resetField={resetField}
        documentType={EntityTypeEnum.Quotation}
      />
      {(data?.quotation?.aggrid_status === "accepted" ||
        data?.quotation?.aggrid_status === "finished" ||
        (data?.quotation?.main_status === "accepted" &&
          data?.quotation?.aggrid_status === "cancelled") ||
        (data?.quotation?.main_status === "finished" &&
          data?.quotation?.aggrid_status === "cancelled")) && (
        <CustomizedBox>
          <AcceptForm
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        </CustomizedBox>
      )}
      <Box sx={{ mt: "4rem" }} />
      {(![
        "not_approved",
        "wait_accept",
        "accepted",
        "cancelled",
        "finished",
      ].includes(data?.quotation?.aggrid_status || "") ||
        ([
          "not_approved",
          "wait_accept",
          "accepted",
          "cancelled",
          "finished",
        ].includes(data?.quotation?.aggrid_status || "") &&
          isEdit)) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default DocumentInfoTab;
