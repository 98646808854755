import {
  useEffect,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";

import {
  ICreatedBy,
  IDefaultForm,
  IMenuOption,
} from "../../../../types/global";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import { useConfirmation } from "../../../../hooks/use-confrimation";
import { usePrintOptions } from "../../../../hooks/Manufacture/use-print-options";
import { useModifyOptions } from "../../../../hooks/Manufacture/use-modify-options";
import { useCreateOptions } from "../../../../hooks/Manufacture/use-create-options";
import { useStatusOptions } from "../../../../hooks/Manufacture/use-status-options";

import { IWorkOrder } from "../../../../types/Manufacture/workOrder";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedChips from "../../../Custom/CustomizedChips";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import EmployeeListForm from "../../EmployeeList";

import Confirmation from "../../../UI/Confirmation";
import ChangeStatusConfirmation from "../../../UI/ChangeStatusConfirmation";

import {
  ManufactureEntityType,
  ModelType,
  useManufactureDocumentCancelMutation,
  useManufactureDocumentSetStepMutation,
  useManufactureOrderUpdateMutation,
  useManufactureUniqueIdQuery,
} from "../../../../generated/manufacture";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useItemSkuDetailQuery } from "../../../../generated/inventory";
import { useStateContext } from "../../../../contexts/auth-context";
import { createInventoryDocumentFromManufactureOrder } from "../../../../utils/Formatter/ManufactureOrder";
import { IItem } from "../../../../types/Inventory/item";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";

type ExtenedProps = IDefaultForm & {
  setDisabled: Dispatch<SetStateAction<boolean>>;
  copyDocumentHandler: () => void;
};

type ModalStatus = {
  value: boolean;
  currStatus?: string;
  nextStatus: string;
};

const mapOption = (option?: string | null) => {
  switch (option) {
    case "in_progress":
      return "กำลังผลิต";
    default:
      break;
  }
};

const defaultValues = {
  actual_production_qty: 0,
  remark: "",
};

const ManufactureOrderHeaderForm = ({
  control,
  errors,
  disabled,
  setValue,
  getValues,
  setDisabled,
  copyDocumentHandler,
}: ExtenedProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [currStatus, setCurrStatus] = useState<string | null>(null);

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control: changeStatusControl,
    getValues: changeStatusGetValues,
    setValue: changeStatusSetValue,
    reset: changeStatusReset,
  } = useForm({ defaultValues });

  const itemUniqueId = useWatch({ control, name: "item_unique_id" });
  const uom = useWatch({ control, name: "uom" });
  const ingredientList = useWatch({ control, name: "ingredient_list" });

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const graphQLClientWithHeaderInventory: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch: getItemData } = useItemSkuDetailQuery(
    graphQLClientWithHeaderInventory,
    {
      uniqueInput: { sku_name: itemUniqueId || "" },
    },
    {
      enabled: false,
    }
  );

  const { refetch: getUniqueId } = useManufactureUniqueIdQuery(
    graphQLClientWithHeaderManufacture,
    {
      modelType: ModelType.ManufactureOrder,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { mutate: updateMo } = useManufactureOrderUpdateMutation<Error>(
    graphQLClientWithHeaderManufacture,
    {
      onSuccess({ manufactureOrderUpdate }, variables, context) {
        setValue("aggrid_status", manufactureOrderUpdate?.aggrid_status);
        setValue("sub_status", manufactureOrderUpdate?.sub_status);
        setValue("remark", manufactureOrderUpdate?.remark);
        setValue(
          "actual_production_qty",
          manufactureOrderUpdate?.actual_production_qty
        );
      },
    }
  );

  const { mutate: setStep } = useManufactureDocumentSetStepMutation(
    graphQLClientWithHeaderManufacture,
    {
      onSuccess({ manufactureDocumentSetStep }, variables, context) {
        if (manufactureDocumentSetStep?.step_number === 3) {
          updateMo({
            uniqueInput: { unique_id: id || "" },
            updateInput: { sub_status: "in_progress" },
          });
          enqueueSnackbar("ปรับสถานะเป็นกำลังผลิตสำเร็จ", {
            variant: "success",
          });
        } else {
          const { remark, actual_production_qty } = changeStatusGetValues();
          updateMo({
            uniqueInput: { unique_id: id || "" },
            updateInput: {
              sub_status: "finished",
              actual_production_qty,
              remark,
            },
          });
          enqueueSnackbar("ปรับสถานะเป็นเสร็จสิ้นสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError(error, variables, context) {
        if (variables.documentSetStepInput.step === 3)
          enqueueSnackbar("ปรับสถานะเป็นกำลังผลิตไม่สำเร็จ", {
            variant: "error",
          });
        else
          enqueueSnackbar("ปรับสถานะเป็นเสร็จสิ้นไม่สำเร็จ", {
            variant: "error",
          });
      },
    }
  );

  const { mutate: cancelMo } = useManufactureDocumentCancelMutation(
    graphQLClientWithHeaderManufacture,
    {
      onSuccess({ manufactureDocumentCancel }, variables, context) {
        setValue("aggrid_status", "cancelled");
        enqueueSnackbar("ยกเลิกใบสั่งผลิตสำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        enqueueSnackbar("ยกเลิกใบสั่งผลิตไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectCreateOptions = [
    {
      value: "ใบนำออก",
      disabled: !(ingredientList && ingredientList.length > 0),
      // disabled: userPermission(user, "inventory", "issue", "create"),
    },
    {
      value: "ใบนำเข้า",
      // disabled: userPermission(user, "inventory", "receive", "create"),
    },
  ];

  const selectStatusOptions = [
    {
      value: "กำลังผลิต",
      disabled: currStatus === "in_progress",
      // disabled: !editPermission || currStatus === "in_progress",
    },
    {
      value: "เสร็จสิ้น",
      // disabled: !editPermission,
    },
  ];

  const watchStatus = useWatch({
    control,
    name: "aggrid_status",
  });

  const watchReferenceUniqueIdList = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const watchWoList = useWatch({
    control,
    name: "work_order_list",
  });

  useEffect(() => {
    switch (watchStatus) {
      case "pending_manu":
        setCurrStatus("pending_manu");
        break;
      case "in_progress":
        setCurrStatus("in_progress");
        break;
      default:
        setCurrStatus(watchStatus);
    }
  }, [watchStatus]);

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },
    {
      label: t("status.pending_manu"),
      value: "pending_manu",
    },
    {
      label: t("status.in_progress"),
      value: "in_progress",
    },
    {
      label: t("status.finished"),
      value: "finished",
    },
  ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(
    watchStatus,
    watchMainStatus,
    ManufactureEntityType.ManufactureOrder
  );

  const selectModifyOptions = useModifyOptions(watchStatus);
  const disabledCreateOptions = useCreateOptions(watchStatus);
  const disabledStatusOptions = useStatusOptions(watchStatus);
  const disabledPrintOptions = usePrintOptions(watchStatus);

  const [, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [expectDateIsOpen, setExpectDateIsOpen] = useState<boolean>(false);
  const [expectProductDateIsOpen, setExpectProductDateIsOpen] =
    useState<boolean>(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState<boolean>(false);

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("manufacture.order.index")}`,
      disabled: !id,
    },
  ];

  const [modal, setModal] = useState<ModalStatus>({
    value: false,
    currStatus: "",
    nextStatus: "",
  });

  const openConfirmationHandler = (nextStatus: string) => {
    const { remark, actual_production_qty } = getValues();
    changeStatusSetValue("remark", remark);
    changeStatusSetValue("actual_production_qty", actual_production_qty);
    setModal({
      value: true,
      currStatus: currStatus as unknown as string,
      nextStatus,
    });
  };

  const closeConfirmationHandler = () => {
    setModal((prev) => ({
      ...prev,
      value: false,
    }));
    setTimeout(() => {
      setModal((prev) => ({
        ...prev,
        currStatus: "",
        nextStatus: "",
      }));
      changeStatusReset();
    }, 700);
  };

  const changeToInProgressHandler = () => {
    const documentSetStepInput = {
      unique_id: getValues("unique_id"),
      reference_document_type: ManufactureEntityType.ManufactureOrder,
      step: 3,
    };
    setStep({ documentSetStepInput });
  };

  const changeToFinishedHandler = () => {
    const documentSetStepInput = {
      unique_id: getValues("unique_id"),
      reference_document_type: ManufactureEntityType.ManufactureOrder,
      step: 4,
    };
    setStep({ documentSetStepInput });
  };

  const confirmationAction = () => {
    if (modal.nextStatus === "in_progress") changeToInProgressHandler();
    else changeToFinishedHandler();
    closeConfirmationHandler();
  };

  const checkConditionStatus = () => {
    const woList = getValues("work_order_list");
    if (!woList || woList === undefined) return false;
    const checkStatusIsFinished = woList.every(
      (wo: IWorkOrder) => wo.main_status === "finished"
    );
    if (!checkStatusIsFinished)
      if (modal.nextStatus === "in_progress") return false;
      else return true;
    else return false;
  };

  const cancelDocumentHandler = () => {
    const unique_id = getValues("unique_id");
    cancelMo({
      documentInput: {
        reference_document_type: ManufactureEntityType.ManufactureOrder,
        unique_id,
      },
    });
  };

  const copyConfirmationAction = () => {
    copyDocumentHandler();
    enqueueSnackbar("คัดลอกสำเร็จ", {
      variant: "success",
    });
  };

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyConfirmationAction);

  return (
    <Fragment>
      <Grid container my={3}>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} mb={3}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("manufacture.order.index")}</Typography>
            <CustomizedStatus status={watchStatus} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          <Grid container spacing={1}>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === "พิมพ์ใบสั่งผลิต") {
                    navigate(
                      `/manufacture/order/${encodeURIComponent(id ?? "")}/pdf`
                    );
                  }
                }}
                disabled={!id || disabledPrintOptions}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      setDisabled(false);
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openCancelConfirmation();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={async (e) => {
                  const value = e.target as HTMLElement;
                  const currentState = getValues();
                  const currentUser: ICreatedBy = {
                    user_unique_id: authUser?.unique_id || "",
                    email: authUser?.email || "",
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name,
                    img_url: authUser?.img_url,
                  };
                  const { data } = await getItemData();
                  switch (value.innerText) {
                    case "ใบนำเข้า":
                      navigate("/inventory/goods_receive/add", {
                        state:
                          await createInventoryDocumentFromManufactureOrder(
                            currentState,
                            currentUser,
                            data?.itemSkuDetail?.item_sku?.item as IItem,
                            "gr"
                          ),
                      });
                      break;
                    case "ใบนำออก":
                      navigate("/inventory/goods_issue/add", {
                        state:
                          await createInventoryDocumentFromManufactureOrder(
                            currentState,
                            currentUser,
                            data?.itemSkuDetail?.item_sku?.item as IItem,
                            "gi"
                          ),
                      });
                      break;
                    default:
                  }
                }}
                variant="contained"
                disabled={disabledCreateOptions}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สถานะการผลิต"}
                options={selectStatusOptions.map(({ value, disabled }) => ({
                  value: value,
                  disabled:
                    disabled ?? value === mapOption(watchStatus) ? true : false,
                }))}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "กำลังผลิต":
                      openConfirmationHandler("in_progress");
                      break;
                    case "เสร็จสิ้น":
                      openConfirmationHandler("finished");
                      break;
                    default:
                  }
                }}
                variant="contained"
                disabled={disabledStatusOptions}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item md={12} lg={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={12} md={2} lg={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.unique_id")}
              </Typography>
            </Grid>
            <Grid item sm={11} md={9} lg={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={Boolean(id)}
              />
            </Grid>
            <Grid item md={1} lg={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item sm={12} md={2} lg={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.reference_unique_id_list")}
              </Typography>
            </Grid>
            <Grid item sm={11} md={10} lg={9}>
              {watchReferenceUniqueIdList &&
              watchReferenceUniqueIdList.length > 0
                ? watchReferenceUniqueIdList.map((reference: any) => (
                    <CustomizedChips
                      onClick={() =>
                        window.open(
                          `/sales/order/${encodeURIComponent(reference)}`,
                          "_blank"
                        )
                      }
                      value={reference}
                      color="primary"
                    />
                  ))
                : "-"}
            </Grid>
            <Grid item sm={12} md={2} lg={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item sm={11} md={9} lg={7}>
              <ControlledTextField
                name="external_reference_id"
                label={t("manufacture.order.external_reference_id")}
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item sm={12} md={2} lg={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.employee_list")}
              </Typography>
            </Grid>
            <Grid item sm={11} md={10} lg={9}>
              <EmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={watchStatus !== "finished" ? disabled : true}
                lockUsers={watchWoList?.reduce((list: any, curr: any) => {
                  curr.responsible_user_list?.forEach((userList: any) =>
                    list.push(userList.user_unique_id)
                  );
                  return list;
                }, [])}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item sm={6} md={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("manufacture.bom.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("manufacture.order.issue_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
                required
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <ControlledDatePicker
                name="production_date"
                control={control}
                error={errors.production_date}
                open={expectDateIsOpen}
                onClose={() => setExpectDateIsOpen(false)}
                onOpen={() => setExpectDateIsOpen(true)}
                label={t("manufacture.order.production_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <ControlledDatePicker
                name="production_completion_date"
                control={control}
                error={errors.production_completion_date}
                open={expectProductDateIsOpen}
                onClose={() => setExpectProductDateIsOpen(false)}
                onOpen={() => setExpectProductDateIsOpen(true)}
                label={t("manufacture.order.production_completion_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                error={errors.delivery_date}
                open={deliveryDateIsOpen}
                onClose={() => setDeliveryDateIsOpen(false)}
                onOpen={() => setDeliveryDateIsOpen(true)}
                label={t("manufacture.order.delivery_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ChangeStatusConfirmation
        control={changeStatusControl}
        title="เปลี่ยนสถานะใบสั่งผลิตเป็น"
        message={
          checkConditionStatus()
            ? "สถานะขั้นตอนงานบางรายการยังไม่เสร็จสิ้น"
            : null
        }
        nextStatus={modal.nextStatus ?? ""}
        open={modal.value}
        handleClose={closeConfirmationHandler}
        confirmationAction={confirmationAction}
        uom={uom}
      />
      <Confirmation
        title={t("sentence.cancel_document_title")}
        message={t("sentence.cancel_document_message")}
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
      <Confirmation
        title={t("sentence.copy_document_title")}
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Fragment>
  );
};

export default ManufactureOrderHeaderForm;
