import AgGrid from "../../../UI/AgGrid";
import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GridReadyEvent, RowDoubleClickedEvent } from "ag-grid-community";
import { ItemType } from "../../../../generated/inventory";
import { ITEMS_VIEW } from "../../../../services/AgGrid/InventoryAgGrid";
import { IServerSideDatasource } from "ag-grid-community";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useItemViewColumnDefs } from "./viewColumnDefs";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";

interface Props {
  gridRef: any;
  isFilter?: string | null;
}

const ItemsViewTable = ({ gridRef, isFilter }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const columnDefs = useItemViewColumnDefs();

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { is_active, tag_list, created_date, ...otherFilter } = filterModel;

      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          filterType: "array",
          type: "hasSome",
          values: tag_list.values,
        },
        created_date: dateFilterModel(created_date),
      };

      try {
        const { itemViews } = await graphQLClientWithHeaderItem.request(
          ITEMS_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: itemViews.data as any[],
          rowCount: itemViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      switch (isFilter) {
        case "normal":
          params.api.setFilterModel({
            type: {
              values: [ItemType.Normal],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        case "variant":
          params.api.setFilterModel({
            type: {
              values: [ItemType.Variant],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        case "bundle":
          params.api.setFilterModel({
            type: {
              values: [ItemType.Bundle],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        case "service":
          params.api.setFilterModel({
            type: {
              values: [ItemType.Service],
            },
            is_active: {
              values: ["1"],
            },
          });
          break;
        case "in_active":
          params.api.setFilterModel({
            type: undefined,
            is_active: {
              values: ["0"],
            },
          });
          break;
        default:
          params.api.setFilterModel({
            type: undefined,
            is_active: {
              values: ["1"],
            },
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { sku_name } = params.data;
    navigate(
      `${pathname}/${encodeURIComponent(sku_name)}?tab=item&subtab=general`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={rowSelectHandler}
      path={"/inventory/items"}
      paginationSize={50}
    />
  );
};

export default ItemsViewTable;
