import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CurrentStockByWarehouse from "../../../components/Table/Inventory/Item/CurrentStock/Warehouse";
import CurrentStockBySerialNumber from "../../../components/Table/Inventory/Item/CurrentStock/SerialNumber";

import { ITab } from "../../../types/global";
import CurrentStockByBinLocation from "../../../components/Table/Inventory/Item/CurrentStock/BinLocation";
import { Box } from "@mui/material";

const ItemCurrentStockTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");

  const tabs: ITab[] = [
    {
      label: t("inventory.items.onHandItemWarehouseTab"),
      path: `${pathname}?tab=currentstock`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseLocation"),
      path: `${pathname}?tab=currentstock&filter=bin_location`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseSN"),
      path: `${pathname}?tab=currentstock&filter=serial_number`,
    },
  ];

  const currentTab = `${pathname}?tab=currentstock${
    filter ? `&filter=${filter}` : ""
  }`;

  const renderfilter = (filter: string | null) => {
    switch (filter) {
      case "bin_location":
        return <CurrentStockByBinLocation />;
      case "serial_number":
        return <CurrentStockBySerialNumber />;
      default:
        return <CurrentStockByWarehouse />;
    }
  };

  return (
    <Box mt={2}>
      <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
      {renderfilter(filter)}
    </Box>
  );
};

export default ItemCurrentStockTab;
