import {
  ColDef,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useManufactureOrderColumnDef } from "../Order/columnDefs";
import { GraphQLClient } from "graphql-request";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GET_MANUFACTURE_ORDER_REPORT_VIEW } from "../../../../services/AgGrid/ManufactureReportAgGrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
  onFilterChanged: (params: any) => void;
}

const ManufactureOrderReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const navigate = useNavigate();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const columnDef: ColDef[] | undefined = useManufactureOrderColumnDef(true);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/order/${encodeURIComponent(params.data.unique_id)}`);
  };

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        issue_date,
        production_date,
        production_completion_date,
        delivery_date,
        created_date,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        issue_date: dateFilterModel(issue_date),
        production_date: dateFilterModel(production_date),
        production_completion_date: dateFilterModel(production_completion_date),
        delivery_date: dateFilterModel(delivery_date),
        created_date: dateFilterModel(created_date),
      };
      try {
        const { ManufactureOrderExportViewsAggrid } =
          await graphQLClient.request(GET_MANUFACTURE_ORDER_REPORT_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: ManufactureOrderExportViewsAggrid.results as any[],
          rowCount: ManufactureOrderExportViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default ManufactureOrderReportTable;
