import { Box } from "@mui/material";
import ModalUI from "../UI/ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { itemColumnDefs } from "./Inventory/Item/columnDefs";
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
} from "ag-grid-community";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import AgGrid from "../UI/AgGrid";
import { forwardRef, useCallback } from "react";
import {
  InventoryTagsFindByEntityQuery,
  ItemEntityType,
  ItemType,
  useInventoryTagsFindByEntityQuery,
} from "../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

interface Props {
  itemModal: boolean;
  noService?: boolean;
  closeItemModalHandler: () => void;
  addItemsHandler: () => void;
}

const ItemTableModal = forwardRef<any, Props>(
  ({ itemModal, closeItemModalHandler, addItemsHandler, noService }, ref) => {
    const { t } = useTranslation();

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { data: tagList } =
      useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
        graphQLClientWithHeaderItem,
        {
          entityName: ItemEntityType.Item,
        }
      );

    const columnDefs: ColDef[] = itemColumnDefs(
      t,
      false,
      true,
      tagList?.itemTagsFindByEntity ?? [],
      noService
    );

    const datasource: IServerSideDatasource = {
      async getRows(params) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const {
          name,
          desc,
          type,
          is_active,
          tag_list,
          item_level_1,
          ...otherFilter
        } = filterModel;

        const formatFilter = {
          ...otherFilter,
          is_active: is_active && {
            ...is_active,
            values: is_active.values.map((v: string) => parseInt(v)),
          },
          item_sku: (name || desc || type || tag_list || item_level_1) && {
            filterType: "object",
            filter: {
              item: {
                filterType: "object",
                filter: {
                  name: name && name,
                  desc: desc && desc,
                  type: type && type,
                  tag_list: tag_list && {
                    filterType: "objectArray",
                    type: "some",
                    filter: {
                      name: {
                        filterType: "set",
                        values: tag_list.values,
                      },
                    },
                  },
                  item_level_1: item_level_1 && {
                    filterType: "object",
                    filter: {
                      name: item_level_1,
                    },
                  },
                },
              },
            },
          },
        };

        try {
          const { itemSkuDetailsFindManyAggrid } =
            await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            });
          params.success({
            rowData: itemSkuDetailsFindManyAggrid.data as any[],
            rowCount: itemSkuDetailsFindManyAggrid.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      const statusInstance = params.api.getFilterInstance("is_active");
      const typeInstance = params.api.getFilterInstance("type");

      typeInstance?.setModel({
        values: noService
          ? [ItemType.Normal, ItemType.Variant]
          : [ItemType.Normal, ItemType.Variant, ItemType.Service],
      });
      statusInstance?.setModel({
        values: ["1"],
      });

      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
      return params.data.sku_name;
    }, []);

    return (
      <ModalUI
        open={itemModal}
        handleClose={closeItemModalHandler}
        title="สินค้า"
        maxWidth="lg"
        action={
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeItemModalHandler}
            />
            <CustomizedButton
              title={t("button.add")}
              onClick={addItemsHandler}
              variant="contained"
              size="medium"
            />
          </Box>
        }
      >
        <AgGrid
          ref={ref}
          columnDefs={columnDefs}
          height={665}
          onGridReady={onGridReady}
          rowSelection="multiple"
          getRowId={getRowId}
          rowMultiSelectWithClick
        />
      </ModalUI>
    );
  }
);

export default ItemTableModal;
