import { useTranslation } from "react-i18next";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import { Typography } from "@mui/material";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useRemarkTemplatesQuery } from "../../../generated/remark";
import { useCallback } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  documentType: string;
}

const Remark = ({
  control,
  errors,
  disabled,
  setValue,
  documentType,
}: Props) => {
  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data } = useRemarkTemplatesQuery(graphQLClientWithHeaderSetting, {
    whereInput: {
      entity_type: {
        equals: documentType,
      },
    },
  });
  const { t } = useTranslation();

  const options: ISelectOption[] =
    data?.remarkTemplates.map((template) => ({
      id: template.id,
      label: template.name,
      value: template.id,
    })) || [];

  const mapTemplateRemarkIdToRemark = useCallback(
    (id: number | null) => {
      if (id) {
        const findById = data?.remarkTemplates.find(
          (template) => template.id === id
        );
        if (findById) setValue("remark", findById.remark_text);
      } else {
        setValue("remark", "");
      }
    },
    [data?.remarkTemplates, setValue]
  );

  const mapTemplateToValue = useCallback(
    (id: number) => {
      if (id) {
        const findById = data?.remarkTemplates.find(
          (template) => template.id === id
        );
        if (findById) {
          return findById.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    [data?.remarkTemplates]
  );

  return (
    <>
      {disabled ? (
        <Typography fontWeight="bold">หมายเหตุ</Typography>
      ) : (
        <Controller
          name="template_remark_id"
          control={control}
          render={({ field }) => (
            <CustomizedComboBox
              {...field}
              label={t("sales.remark")}
              options={options}
              onChange={(_, value) => {
                if (value) {
                  mapTemplateRemarkIdToRemark(value.value);
                  field.onChange(parseInt(value.value));
                } else {
                  mapTemplateRemarkIdToRemark(null);
                  field.onChange(null);
                }
              }}
              value={mapTemplateToValue(field.value)}
              disabled={disabled}
              error={Boolean(errors.template_remark_id)}
            />
          )}
        />
      )}
      <ControlledTextField
        name="remark"
        control={control}
        error={Boolean(errors.remark)}
        helperText={errors.remark && errors.remark.message}
        disabled={disabled}
        sx={{ mt: 1.5 }}
        multiline
        minRows={4}
        maxRows={10}
        onChange={(e, field) => {
          const inputValue = e.target.value;
          const lines = inputValue.split("\n");
          if (lines.length <= 10) {
            field.onChange(inputValue);
          }
        }}
      />
    </>
  );
};

export default Remark;
