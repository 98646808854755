import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { TFunction } from "i18next";
import { Box, Typography } from "@mui/material";
import { formatDate } from "../../../../utils/Formatter/Date";
import { formatNumber } from "../../../../utils/dataTransformer";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

interface Props {
  t: TFunction;
  disabled: boolean;
}

export const usePurchaseItemPriceHistoryColumnDefs = (
  t: Props["t"],
  disabled: Props["disabled"]
): ColDef[] => {
  return [
    {
      field: "reference_unique_id",
      headerName: t("purchase.unique_id"),
      filter: "agTextColumnFilter",
      checkboxSelection: !disabled,
    },
    {
      field: "document_type",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["purchase_order"],
      },
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "vendor_full_name",
      headerName: t("purchase.vendor_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "vendor_contact_unique_id",
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "document_issue_date",
      headerName: t("date.issue_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      sort: "desc",
    },
    {
      field: "item_unique_id",
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "price_per_unit",
      headerName: t("reports.price_per_unit"),
      headerClass: "ag-right-aligned-header",
      filter: false,
      cellRenderer: (params: any) => {
        return (
         <Box width={"100%"} textAlign="right">
            {formatNumber(params.value)}
        </Box>
        );
      },
    },
    {
      field: "qty",
      headerName: t("inventory.quantity"),
      headerClass: "ag-right-aligned-header",
      filter: false,
      cellRenderer: (params: any) => {
        return (
          <Box sx={{
            display: "flex",
            width: "100%",
            textAlign: "right",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <Typography fontSize={14}>{formatNumber(params.value)}</Typography>
            <Typography variant="caption" color={"#737373"}>
              {params.data.uom}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "discount",
      headerName: t("reports.discount_amount_per_unit"),
      headerClass: "ag-right-aligned-header",
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => {
        const discountLabel = params.data.discount_type === 'baht' ? 'บาท' : params.data.discount_type === 'percent' ? '%' : '';
        return (
          <Box sx={{
            display: "flex",
            width: "100%",
            textAlign: "right",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <Typography fontSize={14}>{formatNumber(params.value)}</Typography>
            <Typography variant="caption" color={"#737373"}>
              {discountLabel}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "vat_percentage",
      headerName: "ภาษี",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["7", "0", "ไม่มี"],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case "7":
              return "7 %";
            case "0":
              return "0 %";
            case "ไม่มี":
              return "ไม่มี";
            default:
              return params.value;
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        switch (params.value) {
          case "7":
            return "7 %";
          case "0":
            return "0 %";
          case "ไม่มี":
            return "ไม่มี";
          default:
            return params.value;
        }
      },
    },
    {
      field: "remark",
      headerName: t("reports.item_remark"),
      filter: "agTextColumnFilter",
    },
  ];
};
