import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PurchaseRequestQuery } from "../../../generated/purchase";

interface Props {
  data: PurchaseRequestQuery["purchaseRequest"];
}

const PurchaseRequestPdfTable = ({ data }: Props) => {
  const header = [
    {
      label: "No.",
      thaiLabel: "รายการ",
      width: 55,
    },
    {
      label: "Name",
      thaiLabel: "ชื่อสินค้า",
      width: 250,
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: 80,
    },
    {
      label: "Unit",
      thaiLabel: "หน่วย",
      width: 100,
    },
    {
      label: "PO Qty",
      thaiLabel: "สั่งซื้อแล้ว",
      width: 80,
    },
    {
      label: "Remark",
      thaiLabel: "หมายเหตุ",
      width: 150,
    },
  ];
  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#F2EEFC" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.item_list &&
            data.item_list.map((item, index) => (
              <TableRow key={item.item_unique_id} sx={{ breakInside: "avoid" }}>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12} fontWeight={600}>
                    {item.item_name}
                  </Typography>
                  <Typography
                    fontSize={12}
                    sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                  >
                    {item.item_sku_desc}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.qty}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.uom}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.qty_ordered}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.remark || "-"}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseRequestPdfTable;
