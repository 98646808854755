import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CustomizedLetterAvatar from "./CustomizedLetterAvatar";
import { CustomizedTooltip } from "./CustomizedTooltip";
import { IAvatar } from "../../types/global";
import { SxProps } from "@mui/material";

interface Props {
  avatars: IAvatar[];
  sx?: SxProps;
  noTooltip?: boolean;
  variant?: "circular" | "rounded" | "square";
}

const CustomizedAvatar = ({ avatars, sx, noTooltip, variant }: Props) => {
  return (
    <AvatarGroup max={3} sx={{ justifyContent: "flex-end" }}>
      {avatars?.map((avatar: IAvatar) => {
        if (avatar) {
          if (avatar.img_url && avatar.img_url.length > 0) {
            if (noTooltip) {
              return (
                <Avatar
                  alt={`img${avatar.unique_id || avatar.user_unique_id}`}
                  src={avatar.img_url}
                  key={`img${avatar.unique_id || avatar.user_unique_id}`}
                  sx={sx}
                  variant={variant ? variant : "circular"}
                />
              );
            } else {
              return (
                <CustomizedTooltip
                  title={`${avatar.first_name} ${avatar.last_name}`}
                  key={avatar.unique_id || avatar.user_unique_id}
                >
                  <Avatar
                    alt={`img${avatar.unique_id || avatar.user_unique_id}`}
                    src={avatar.img_url}
                    sx={sx}
                    key={`img${avatar.unique_id || avatar.user_unique_id}`}
                    variant={variant ? variant : "circular"}
                  />
                </CustomizedTooltip>
              );
            }
          } else {
            return (
              <CustomizedLetterAvatar
                name={avatar.first_name + " " + avatar.last_name}
                key={avatar.unique_id || avatar.user_unique_id}
                sx={sx}
                noTooltip={noTooltip}
                variant={variant}
              />
            );
          }
        } else {
          return null;
        }
      })}
    </AvatarGroup>
  );
};

export default CustomizedAvatar;
