import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import { AdditionalDiscountType } from "../../../generated/purchase";
import { bahttext } from "bahttext";
import { ISalesItemList } from "../../../types/Sales";
import { PriceVatType } from "../../../generated/sales";

const CustomizedSummary = styled(Box)({
  display: "grid",
  justifyContent: "flex-end",
  textAlign: "right",
  gridGap: "10px",
  gap: "10px",
  paddingRight: "30px",
  paddingTop: "4px",
  paddingBottom: "4px",
  gridTemplateColumns:
    "minmax(min-content,max-content) minmax(6rem,max-content) 24px",
});

interface Props {
  data: any;
  documentType?: string;
  isPurchase?: boolean;
  bgColor?: string;
  textColor?: string;
}

const PDFFooter = ({
  data,
  documentType,
  isPurchase,
  bgColor,
  textColor,
}: Props) => {
  const sxColor = isPurchase ? "#333333" : textColor ? textColor : "#2167D3";

  return (
    <>
      <Grid container sx={{ breakInside: "avoid" }}>
        {documentType !== "delivery_order" && (
          <Grid item xs={12}>
            <CustomizedSummary
              bgcolor={isPurchase ? "#FCE8E8" : bgColor ? bgColor : "#ECF6FD"}
              borderRadius="4px"
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                มูลค่ารวมก่อนภาษี
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {formatNumber(
                  data.item_list.reduce(
                    (acc: number, item: ISalesItemList) =>
                      acc + item.pre_discount_amount,
                    0
                  ) || data.pre_vat_amount
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          </Grid>
        )}
        <Grid item xs={6}>
          <Box sx={{ display: "block", gap: 0.75, mt: 1.7 }}>
            <Typography sx={{ fontSize: 11, fontWeight: 600 }}>
              หมายเหตุ
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                mr: 2,
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {data.remark}
            </Typography>
          </Box>
        </Grid>
        {!["purchase_request", "delivery_order"].includes(
          documentType || "-"
        ) && (
          <Grid item xs={6}>
            <Box mt={1}>
              {data.additional_discount > 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    ส่วนลดก่อนภาษี
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {formatNumber(
                      data.item_list?.reduce(
                        (acc: number, item: ISalesItemList) => {
                          if (
                            data.price_vat_type === PriceVatType.IncludedVat
                          ) {
                            if (item.vat_percentage === "7") {
                              acc +=
                                data.additional_discount *
                                ((item.pre_vat_amount / data.pre_vat_amount ||
                                  0) /
                                  1.07);
                            } else
                              acc +=
                                data.additional_discount *
                                (item.pre_vat_amount / data.pre_vat_amount);
                          } else
                            acc +=
                              data.additional_discount *
                              (item.pre_vat_amount / data.pre_vat_amount);
                          return acc;
                        },
                        0
                      )
                    ) || 0}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {data.additional_discount_type ===
                    AdditionalDiscountType.Percent
                      ? "%"
                      : "บาท"}
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
              {data.additional_discount !== 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    มูลค่าหลังหักส่วนลด
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {formatNumber(data.sub_total)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    บาท
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  ภาษีมูลค่าเพิ่มรวม
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {formatNumber(data.vat_amount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            </Box>
            <CustomizedSummary
              bgcolor={isPurchase ? "#FCE8E8" : bgColor ? bgColor : "#ECF6FD"}
              borderRadius="4px"
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                มูลค่ารวมสุทธิ
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {formatNumber(data.net_amount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
            {data.withholding_tax_amount > 0 && (
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  ภาษีหัก ณ ที่จ่าย
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {formatNumber(data.withholding_tax_amount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            )}
            {data.shipping_cost > 0 && (
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  ค่าส่ง
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {formatNumber(data.shipping_cost)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            )}
            <CustomizedSummary>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                จำนวนเงินรวมทั้งสิ้น
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {formatNumber(data.total_amount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
            <Box mr="30px">
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  textAlign: "right",
                }}
              >
                ({bahttext(roundingNumber(data.total_amount))})
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PDFFooter;
