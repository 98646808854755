import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "../../../../UI/AgGrid";
import { useCurrentStockColumnDefs } from "./useCurrentStockColumnDefs";
import { GridReadyEvent, IServerSideDatasource } from "ag-grid-community";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { ITEM_SKU_QTY_BY_WAREHOUSE_VIEW } from "../../../../../services/AgGrid/InventoryAgGrid";
import { useParams } from "react-router-dom";

const CurrentStockByWarehouse = () => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = useCurrentStockColumnDefs("warehouse");
  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("item");
  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const formatFilter = {
        ...filterModel,
        sku_name: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
      };

      try {
        const { ItemSkuQtysByWarehouseView } = await graphQLClient.request(
          ITEM_SKU_QTY_BY_WAREHOUSE_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: ItemSkuQtysByWarehouseView.data as any[],
          rowCount: ItemSkuQtysByWarehouseView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.onFilterChanged();
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onGridReady={onGridReady}
      disabledSidebar
      paginationSize={50}
    />
  );
};

export default CurrentStockByWarehouse;
