import { Fragment, useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Box, Divider, IconButton, Typography } from "@mui/material";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import addressData from "../../../data/address.json";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import { IBranch } from "../../../types/Setting/company";
import CloseIcon from "@mui/icons-material/Close";

const defaultNewBranchValues: IBranch = {
  unique_id: "",
  name: "",
  address: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  country: "",
  phone: "",
  fax: "",
  email: "",
};

const BranchAddressForm = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
}: any) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "branch_list",
  });

  const [subDistrictOptions, setSubDistrictOptions] = useState<string[]>([]);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<string[]>([]);
  const [searchSubDistrict, setSearchSubDistrict] = useState<
    string | null | undefined
  >("");
  const [searchDistrict, setSearchDistrict] = useState<
    string | null | undefined
  >("");
  const [searchProvince, setSearchProvince] = useState<
    string | null | undefined
  >("");

  const { t } = useTranslation();

  const updateFilter = useCallback(() => {
    let filteredAddressData = addressData;
    if (searchSubDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.sub_district.includes(searchSubDistrict)
      );
    }
    if (searchDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.district.includes(searchDistrict)
      );
    }
    if (searchProvince) {
      filteredAddressData = addressData.filter((item) =>
        item.province.includes(searchProvince)
      );
    }
    let filteredProvinces: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredProvinces.includes(item.province)) {
        filteredProvinces = [...filteredProvinces, item.province];
      }
    });
    setProvinceOptions(filteredProvinces);

    let filteredDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredDistricts.includes(item.district)) {
        filteredDistricts = [...filteredDistricts, item.district];
      }
    });
    setDistrictOptions(filteredDistricts);

    let filteredSubDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredSubDistricts.includes(item.sub_district)) {
        filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
      }
    });
    setSubDistrictOptions(filteredSubDistricts);
  }, [searchDistrict, searchProvince, searchSubDistrict]);

  useEffect(() => {
    updateFilter();
  }, [updateFilter]);

  const countryOptions = ["ไทย"];

  const addNewAddressHandler = () => {
    setSearchSubDistrict(undefined);
    setSearchDistrict(undefined);
    setSearchProvince(undefined);
    append({ ...defaultNewBranchValues });
  };

  const renderBranch = () =>
    fields && fields.length > 0 ? (
      fields.map((branch, index: number) => (
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#ECF6FDCC",
            mb: 2,
            padding: "16px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name={`branch_list.${index}.unique_id`}
                error={Boolean(errors.branch_list?.[index]?.unique_id)}
                helperText={errors.branch_list?.[index]?.unique_id?.message}
                label="รหัส"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name={`branch_list.${index}.name`}
                error={Boolean(errors.branch_list?.[index]?.name)}
                helperText={errors.branch_list?.[index]?.name?.message}
                label="ชื่อสาขา"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} md={4} textAlign="end">
              {!disabled && (
                <IconButton
                  aria-label="delete"
                  onClick={() => remove(index)}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Typography fontWeight="bold" my={2}>
            ที่อยู่
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={`branch_list.${index}.address`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(errors.branch_list?.[index]?.address)}
                    helperText={errors.branch_list?.[index]?.address?.message}
                    label={t("address.index")}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name={`branch_list.${index}.sub_district`}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    options={subDistrictOptions}
                    label={t("address.sub_district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchSubDistrict(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchSubDistrict(option);
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.sub_district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        let fieldsValue = {
                          ...getValues("branch_list")[index],
                          district: filter[0].district,
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`branch_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name={`branch_list.${index}.district`}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    options={districtOptions}
                    label={t("address.district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchDistrict(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchDistrict(option);
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        const filteredSubdistricts = filter.map(
                          (address) => address.sub_district
                        );
                        setSubDistrictOptions(filteredSubdistricts);
                        let fieldsValue = {
                          ...getValues("branch_list")[index],
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`branch_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name={`branch_list.${index}.province`}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    options={provinceOptions}
                    label={t("address.province")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchProvince(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchProvince(option);
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      setValue(`branch_list.${index}.country`, "ไทย");
                      return field.onChange(option);
                    }}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name={`branch_list.${index}.postal_code`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(errors.branch_list?.[index]?.postal_code)}
                    helperText={
                      errors.branch_list?.[index]?.postal_code?.message
                    }
                    label={t("address.postal_code")}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name={`branch_list.${index}.country`}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    options={countryOptions}
                    label={t("address.country")}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography fontWeight="bold" my={2}>
            ช่องทางติดต่อ
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name={`branch_list.${index}.phone`}
                error={Boolean(errors.branch_list?.[index]?.phone)}
                helperText={errors.branch_list?.[index]?.phone?.message}
                label={t("address.phone_number")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name={`branch_list.${index}.fax`}
                error={Boolean(errors.branch_list?.[index]?.fax)}
                helperText={errors.branch_list?.[index]?.fax?.message}
                label={"Fax"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name={`branch_list.${index}.email`}
                error={Boolean(errors.branch_list?.[index]?.email)}
                helperText={errors.branch_list?.[index]?.email?.message}
                label={"Email"}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
      ))
    ) : (
      <Typography textAlign="center">ไม่มีข้อมูล</Typography>
    );

  return (
    <>
      {renderBranch()}
      {!disabled && fields?.length < 20 && (
        <Box sx={{ my: 2 }}>
          <CustomizedButton
            title={"เพิ่มสาขา"}
            variant="text"
            size="medium"
            onClick={addNewAddressHandler}
            disabled={disabled}
            addStartIcon
          />
        </Box>
      )}
    </>
  );
};

export default BranchAddressForm;
