import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { ITEM_SKU_QTY_CHECKER } from "../../../../services/Inventory/Item/ItemSkuQtysChecker";
import { ISkuQty } from "../../../../types/Inventory/item";
import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

type Props = {
  sku_name: string;
};

type SumWarehouse = {
  warehouse_name: string;
  warehouse_unique_id: string;
  value: number;
  stock_uom: string;
}[];

const SkuQtyChecker = ({ sku_name }: Props) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const [sumWarehouse, setSumWarehouse] = useState<SumWarehouse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const skuQtyChecker = async (sku_name: string) => {
      const { itemSkuQtys } = await graphQLClientWithHeaderItem.request(
        ITEM_SKU_QTY_CHECKER,
        {
          findManyInput: {
            where: {
              sku_name: {
                equals: sku_name,
              },
            },
          },
        }
      );

      const sumWarehouse: SumWarehouse = [];

      const skuQtyType = itemSkuQtys as ISkuQty[];
      skuQtyType.forEach((skuQty) => {
        const warehouseUniqueId =
          skuQty?.warehouse_level_3?.sub_level_2?.sub_level_1
            ?.warehouse_unique_id ?? "";
        const warehouseName =
          skuQty?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse
            ?.name ?? "";

        const findWarehouse = sumWarehouse.findIndex(
          (res) => res.warehouse_unique_id === warehouseUniqueId
        );
        if (findWarehouse !== -1) {
          sumWarehouse[findWarehouse].value += skuQty.stock_qty;
        } else {
          sumWarehouse.push({
            warehouse_name: warehouseName,
            warehouse_unique_id: warehouseUniqueId,
            value: skuQty.stock_qty,
            stock_uom:
              skuQty.item_sku_detail?.item_sku?.item?.stock_uom?.name || "",
          });
        }
      });
      setSumWarehouse(sumWarehouse);
      setIsLoading(false);
    };

    skuQtyChecker(sku_name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku_name]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={200} height={20} />
      ) : (
        <Box width={200}>
          {sumWarehouse &&
            sumWarehouse.map((v, index) => (
              <Box display="flex" justifyContent="space-between" key={index}>
                <Typography fontSize={14}>{v.warehouse_name}</Typography>
                <Box display="flex" gap={2}>
                  <Typography fontSize={14}>
                    {v.value.toLocaleString()}
                  </Typography>
                  <Typography fontSize={14}>{v.stock_uom}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </>
  );
};

export default SkuQtyChecker;
