import { IImporterError } from "../types/global";

const isAllNull = (item: any) => {
  if (typeof item === "object" && item !== null) {
    for (const val of Object.values(item)) {
      if (!isAllNull(val)) {
        return false;
      }
    }
  } else if (item !== null) {
    return false;
  }
  return true;
};

// function for traversing nested objects
const getNestedObj = (objKeyStr: string, obj: any, objType: string) => {
  let indexFromLast = objType === "working" ? 2 : 1;
  const objKeySplit = objKeyStr.split(".");

  let objPointer;

  objKeySplit.reduce((acc, curr) => {
    if (objKeySplit.indexOf(curr) === objKeySplit.length - indexFromLast) {
      objPointer = acc[curr];
    }
    return acc[curr];
  }, obj);
  return objPointer;
};

// get object 1 level before the deepest
const nestedWorkingObj = (objKeyStr: string, workingObj: any) => {
  return getNestedObj(objKeyStr, workingObj, "working");
};

// get deepest level object
const nestedCurrentObj = (objKeyStr: string, currentObj: any) => {
  return getNestedObj(objKeyStr, currentObj, "current");
};

export const mapNestedData = (
  keys: string[],
  getValues: any,
  enqueueSnackbar: any
) => {
  const inputData = getValues("file");
  // store keys for nested array
  const arrayKeys = keys.filter((key) => Array.isArray(key));

  const tempData = [...inputData];
  const mainId = Object.keys(tempData[0])[0];

  // loops through everything to convert first elements of each specified keys into array
  for (let i = 0; i < tempData.length; i++) {
    // picking object with id to assign other values to it, skip if there's no ID
    let workingObject = { ...tempData[i] };
    if (workingObject[mainId] === null && i === 0) {
      let errorMessage = `กรุณาระบุ ${mainId}`;
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      // setLoading(false);
      return;
    }
    if (workingObject[mainId] === null) {
      continue;
    }
    // convert first elements of each keys to array
    for (let j = 0; j < keys.length; j++) {
      if (Array.isArray(keys[j])) {
        continue;
      }
      // if key is for nested objects, call function to traverse the levels
      if (keys[j].includes(".")) {
        const lastLevelKey = keys[j].split(".").pop() as string;
        const nestedWorkObj = nestedWorkingObj(keys[j], workingObject);
        if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
          if (typeof (nestedWorkObj as any)[lastLevelKey] === "object") {
            (nestedWorkObj as any)[lastLevelKey] = [
              { ...(nestedWorkObj as any)[lastLevelKey] },
            ];
          } else {
            (nestedWorkObj as any)[lastLevelKey] = [
              (nestedWorkObj as any)[lastLevelKey],
            ];
          }
        }
      } else {
        // if (workingObject[keys[j]] === null) {
        //   continue;
        // }
        if (!Array.isArray(workingObject[keys[j]])) {
          workingObject[keys[j]] = [workingObject[keys[j]]];
        }
      }
      tempData[i] = workingObject;
    }
  }

  // loops through everything to assign values into their respective arrays
  for (let i = 0; i < tempData.length; i++) {
    let workingObject = { ...tempData[i] };
    let currentObject;
    if (workingObject[mainId] === null) {
      continue;
    }
    for (let j = i + 1; j < tempData.length; j++) {
      currentObject = { ...tempData[j] };
      if (currentObject[mainId] !== null) {
        break;
      }
      for (let k = 0; k < keys.length; k++) {
        if (Array.isArray(keys[k])) {
          continue;
        }
        // assign values for nested objects
        if (keys[k].includes(".")) {
          const lastLevelKey = keys[k].split(".").pop() as string;
          const nestedWorkObj = nestedWorkingObj(keys[k], workingObject);
          const nestedCurrObj = nestedCurrentObj(keys[k], currentObject);
          if (typeof nestedCurrObj === "object") {
            //skips current key for current row if it's value including nested objects are null
            if (isAllNull(nestedCurrObj)) {
              continue;
            }
            if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
              (nestedWorkObj as any)[lastLevelKey] = [
                { ...(nestedWorkObj as any)[lastLevelKey] },
              ];
            }
            (nestedWorkObj as any)[lastLevelKey] = [
              ...(nestedWorkObj as any)[lastLevelKey],
              nestedCurrObj,
            ];
          } else {
            if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
              (nestedWorkObj as any)[lastLevelKey] = [
                (nestedWorkObj as any)[lastLevelKey],
              ];
            }
            if (nestedCurrObj === null) {
              continue;
            }
            (nestedWorkObj as any)[lastLevelKey] = [
              ...(nestedWorkObj as any)[lastLevelKey],
              nestedCurrObj,
            ];
          }
          continue;
        }

        if (currentObject[keys[k]] === null) {
          continue;
        }

        if (typeof currentObject[keys[k]] === "object") {
          if (isAllNull(currentObject[keys[k]])) {
            continue;
          }
          if (!Array.isArray(workingObject[keys[k]])) {
            workingObject[keys[k]] = [{ ...workingObject[keys[k]] }];
          } else {
            workingObject[keys[k]] = [
              ...workingObject[keys[k]],
              { ...currentObject[keys[k]] },
            ];
          }
        } else {
          if (currentObject[keys[k]] === null) {
            continue;
          }
          if (!Array.isArray(workingObject[keys[k]])) {
            workingObject[keys[k]] = [workingObject[keys[k]]];
          } else {
            workingObject[keys[k]] = [
              ...workingObject[keys[k]],
              currentObject[keys[k]],
            ];
          }
        }
      }

      tempData[i] = workingObject;
    }
  }
  // map for data with nested array
  for (let i = 0; i < tempData.length; i++) {
    let workingObject = { ...tempData[i] };
    if (workingObject[mainId] === null) {
      continue;
    }
    for (let j = 0; j < arrayKeys.length; j++) {
      let nestedWorkObj = nestedCurrentObj(
        `${arrayKeys[j][0]}`,
        workingObject
      ) as any;
      if (!Array.isArray(nestedWorkObj)) {
        continue;
      }
      let indexToAssignTo = 0;
      if (!Array.isArray(nestedWorkObj[indexToAssignTo][arrayKeys[j][1]])) {
        if (
          typeof nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] === "object"
        ) {
          nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
            { ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] },
          ];
        } else {
          nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
            nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
          ];
        }
      }
      for (let k = 0; k < nestedWorkObj.length; k++) {
        if (
          nestedWorkObj[k] &&
          Object.values(nestedWorkObj[k]).every(
            (item) => item === null || typeof item === "object"
          ) &&
          !isAllNull(nestedWorkObj[k][arrayKeys[j][1]])
        ) {
          if (typeof nestedWorkObj[k][arrayKeys[j][1]] === "object") {
            nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
              ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
              { ...nestedWorkObj[k][arrayKeys[j][1]] },
            ];
          } else {
            nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
              ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
              nestedWorkObj[k][arrayKeys[j][1]],
            ];
          }
          nestedWorkObj.splice(k, 1);
          // step back 1 index as current index is gone now
          k--;
        } else {
          if (!Array.isArray(nestedWorkObj[k][arrayKeys[j][1]])) {
            nestedWorkObj[k][arrayKeys[j][1]] = [
              { ...nestedWorkObj[k][arrayKeys[j][1]] },
            ];
          }
          indexToAssignTo = k;
        }
      }
    }
  }
  const filteredTempData = tempData.filter((data) => data[mainId] !== null);
  return filteredTempData;
};

export const addImporterErrorHandler = (
  errorArray: IImporterError[],
  caseString: string,
  nameString: string,
  dataArray: string[]
): void => {
  if (dataArray.length > 0) {
    dataArray.forEach((item) => {
      errorArray.push({
        case: caseString,
        name: nameString,
        value: item,
      });
    });
  }
};

export const findNoneBoolean = (array: any[]) => {
  const boolean = ["ใช่", "ไม่ใช่"];
  const resultArray = array.filter((value) => !boolean.includes(value));
  return resultArray;
};

export const findNoneStatus = (array: any[]) => {
  const status = ["ใช้งาน", "หยุดใช้งาน"];
  const resultArray = array.filter((value) => !status.includes(value));
  return resultArray;
};

export const findNoneContactType = (array: any[]) => {
  const businessType = ["นิติบุคคล", "บุคคลธรรมดา"];
  const resultArray = array.filter((value) => !businessType.includes(value));
  return resultArray;
};

export const findNoneContactTypeDetail = (array: any[]) => {
  const businessTypeDetail = [
    "บริษัท",
    "บริษัทมหาชนจำกัด",
    "ห้างหุ้นส่วนจำกัด",
    "ห้างหุ้นส่วนสามัญนิติบุคคล",
    "สมาคม",
    "อื่นๆ",
    "บุคคลธรรมดา",
    "ห้างหุ้นส่วนสามัญ",
    "ร้านค้า",
    "คณะบุคคล",
  ];
  const resultArray = array.filter(
    (value) => !businessTypeDetail.includes(value)
  );
  return resultArray;
};

export const findNoneContactTypeDetailForJuristicPerson = (array: any[]) => {
  const businessTypeDetail = [
    "บริษัท",
    "บริษัทมหาชนจำกัด",
    "ห้างหุ้นส่วนจำกัด",
    "ห้างหุ้นส่วนสามัญนิติบุคคล",
    "สมาคม",
    "อื่นๆ",
  ];
  const resultArray = array.filter(
    (value) => !businessTypeDetail.includes(value)
  );
  return resultArray;
};

export const findNoneContactTypeDetailForIndividual = (array: any[]) => {
  const businessTypeDetail = [
    "บุคคลธรรมดา",
    "ห้างหุ้นส่วนสามัญ",
    "ร้านค้า",
    "คณะบุคคล",
  ];
  const resultArray = array.filter(
    (value) => !businessTypeDetail.includes(value)
  );
  return resultArray;
};

export const findNoneBranchType = (array: any[]) => {
  const branchType = [
    "สำนักงานใหญ่",
    "สาขา",
    "ไม่ระบุ",
  ];
  const resultArray = array.filter(
    (value) => !branchType.includes(value)
  );
  return resultArray;
}

export const findNoneTitleName = (array: any[]) => {
  const titleName = ["นาย", "นาง", "นางสาว", "คุณ", "ไม่ระบุ"];
  const resultArray = array.filter((value) => !titleName.includes(value));
  return resultArray;
};

export const findNoneSource = (array: any[]) => {
  const source = [
    "Sales",
    "Facebook",
    "Instagram",
    "Website",
    "Email",
    "Line@",
    "โทรศัพท์ call in",
    "Marketplace",
    "อื่นๆ",
  ];
  const resultArray = array.filter((value) => !source.includes(value));
  return resultArray;
};

export const findNoneCountry = (array: any[]) => {
  const country = ["ไทย"];
  const resultArray = array.filter((value) => !country.includes(value));
  return resultArray;
};