import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { formatDateAgGrid } from "../../../utils/Formatter/Date";
import { SortOrder } from "../../../generated/manufacture-export";

import { GENERATE_EXPORT } from "../../../services/AgGrid/ManufactureReportAgGrid";
import { IIngredient } from "../../../types/Manufacture";
import ManufactureIngredientReportTable from "../../../components/Table/Manufacture/Report/Ingredient";

const ManufactureIngredientReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IIngredient>>(null);
  const [showTable, setShowTable] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("report"),
      to: "/manufacture/report",
    },
    {
      name: t("manufacture.sentence.ingredient_report"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBtnExport = async () => {
    try {
      setIsLoading(true);
      const filterModel = gridRef?.current?.api.getFilterModel() as any;
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const {
        issue_date,
        production_date,
        production_completion_date,
        delivery_date,
        created_date,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        issue_date: dateFilterModel(issue_date),
        production_date: dateFilterModel(production_completion_date),
        delivery_date: dateFilterModel(delivery_date),
        production_completion_date: dateFilterModel(production_completion_date),
        created_date: created_date
          ? dateFilterModel(created_date)
          : dateFilterModel({
              filterType: "date",
              type: "inRange",
              dateFrom: dateFrom,
              dateTo: dateTo,
            }),
      };

      const { GenerateExport } =
        await graphQLClientWithHeaderManufacture.request(GENERATE_EXPORT, {
          input: {
            export_type: "manufac_ingredient_report",
            params: {
              aggridInput: {
                startRow: 0,
                endRow: Math.pow(10, 5),
                filterModel: formatFilter,
                sortModel:
                  sortModel?.length !== 0
                    ? sortModel
                    : [
                        {
                          sort: SortOrder.Desc,
                          colId: "created_date",
                        },
                      ],
              },
            },
          },
        });
      window.open(GenerateExport, "_blank");
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });

      //   await graphQLClientWithHeaderManufacture.request(GENERATE_REPORT, {
      //     priority: 1,
      //     reportType: "trace_entry",
      //     params: {
      //       aggridInput: {
      //         startRow: 0,
      //         endRow: Math.pow(10, 5),
      //         filterModel: formatFilter,
      //         sortModel,
      //       },
      //     },
      //   });

      //   enqueueSnackbar("คำขอสำเร็จ ระบบกำลังดำเนินการ", {
      //     variant: "success",
      //   });
    } catch (err) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
      // enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
      //   variant: "error",
      // });
    }
    setIsLoading(false);
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      let startDate = getValues("dateFrom");
      let endDate = getValues("dateTo");

      const created_date = params.api.getFilterInstance("created_date");

      created_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = () => {
    reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("manufacture.sentence.ingredient_report")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={!showTable || isLoading}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
      />
      {showTable && (
        <ManufactureIngredientReportTable
          gridRef={gridRef}
          onFilterChanged={onFilterChanged}
        />
      )}
    </>
  );
};

export default ManufactureIngredientReport;
