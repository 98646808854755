import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import AddressForm from "../Address";
import { IDefaultForm, ITab } from "../../../types/global";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTab from "../../Custom/CustomizedTab";
import { useLocation, useSearchParams } from "react-router-dom";
import BranchAddressForm from "../Address/BranchAddressForm";

const CompanyAddressForm = ({
  control,
  disabled,
  errors,
  getValues,
  setValue,
  handleSubmit,
  reset,
}: IDefaultForm) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const tabs: ITab[] = [
    {
      label: "ที่อยู่",
      path: `${pathname}`,
    },
    {
      label: "สาขา",
      path: `${pathname}?tab=branch`,
    },
  ];

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const renderTabs = (tab: string | null) => {
    switch (tab) {
      case "branch":
        return (
          <BranchAddressForm
            control={control}
            errors={errors}
            disabled={disabled}
            getValues={getValues}
            setValue={setValue}
          />
        );

      default:
        return (
          <AddressForm
            control={control}
            errors={errors}
            disabled={disabled}
            getValues={getValues}
            setValue={setValue}
            handleSubmit={handleSubmit}
            reset={reset}
          />
        );
    }
  };

  return (
    <CustomizedBox padding="1rem">
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      {renderTabs(tab)}
    </CustomizedBox>
  );
};

export default CompanyAddressForm;
