import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Typography } from "@mui/material";

import ManufactureOrderTable from "../../../components/Table/Manufacture/Order";

const ManufactureOrderTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Typography variant="h5" mb={3}>
        {t("manufacture.order.index")}
      </Typography>
      <ManufactureOrderTable referenceUniqueId={id} />
    </Fragment>
  );
};

export default ManufactureOrderTab;
