import * as Yup from "yup";
import dayjs from "dayjs";
import { IManufactureOrder } from "../../../../types/Manufacture/order";

export const manufactureOrderSchema: IManufactureOrder = {
  id: undefined,
  unique_id: "",
  main_status: undefined,
  sub_status: undefined,
  flag_status: undefined,
  aggrid_status: undefined,
  reference_unique_id: undefined,
  external_reference_id: "",
  related_user_list: undefined,
  created_by: undefined,
  created_date: undefined,
  issue_date: dayjs(),
  production_date: dayjs(),
  production_completion_date: dayjs(),
  delivery_date: dayjs(),
  customer_contact_unique_id: "",
  customer_name: "",
  project: undefined,
  item_unique_id: "",
  item_name: "",
  item_description: "",
  item_remark: "",
  production_qty: 1,
  uom: "",
  bom_name: "",
  routing_id: undefined,
  routing_detail: { name: "" },
  type: "",
  template_remark_id: "",
  remark: "",
  tag_list: undefined,
  ingredient_list: undefined,
  work_order_list: undefined,
  goods_receive_qty: 0,
  work_order_cost: 0,
  other_manufacture_cost: 0,
  total_cost: 0,
  bom_cost: 0,
  waste_production_list: undefined,
  attachment_list: [],
};

const ingredientValidation = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  item_description: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
});

const workOrderValidation = Yup.object().shape({
  production_center: Yup.string().required("กรุณาระบุศูนย์การผลิต"),
});

export const manufactureOrderValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  item_description: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร").nullable(),
  production_qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนสั่งผลิต")
    .required("กรุณาระบุจำนวนสั่งผลิต"),
  customer_name: Yup.string().required("กรุณาระบุลูกค้า"),
  item_unique_id: Yup.string().required("กรุณาระบุสินค้า"),
  // bom_name: Yup.string().required("กรุณาระบุสูตรการผลิต"),
  ingredient_list: Yup.array()
    .of(ingredientValidation)
    // .min(1, "กรุณาระบุส่วนประกอบ")
    .nullable(),
  work_order_list: Yup.array().of(workOrderValidation).nullable(),
  remark: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
});
