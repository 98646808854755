import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

// import TotalBox from "../../../components/UI/TotalBox";
import ScanNavigate from "../../../components/UI/ScanNavigate";
import HeaderLayout from "../../../components/UI/HeaderLayout";
// import ItemsTable from "../../../components/Table/Inventory/Item";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { itemSchema } from "../../../components/Form/Inventory/Item/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { ITEMS_EXPORT_FOR_IMPORT } from "../../../services/Inventory/Item/ItemsExportForImport";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ItemsCountQuery,
  ItemsQuery,
  useItemsCountQuery,
} from "../../../generated/inventory";
import ItemsViewTable from "../../../components/Table/Inventory/Item/ItemViewTable";

const Items = () => {
  const gridRef = useRef<AgGridReact<ItemsQuery["items"]>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { enqueueSnackbar } = useSnackbar();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data } = useItemsCountQuery<ItemsCountQuery>(
    graphQLClientWithHeaderItem
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          {t("inventory.all")}
          <Box bgcolor={"primary.main"} borderRadius={3} px={0.5} minWidth={30}>
            <Typography color={"white"} fontSize={14}>
              {data?.itemsCount?.all
                ? data?.itemsCount.all > 99999
                  ? "99,9999+"
                  : data?.itemsCount.all.toLocaleString()
                : "0"}
            </Typography>
          </Box>
        </Box>
      ),
      path: `${pathname}`,
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          {t("inventory.type.normal")}
          <Box bgcolor={"primary.main"} borderRadius={3} px={0.5} minWidth={30}>
            <Typography color={"white"} fontSize={14}>
              {data?.itemsCount?.normal
                ? data?.itemsCount.normal > 99999
                  ? "99,9999+"
                  : data?.itemsCount.normal.toLocaleString()
                : "0"}
            </Typography>
          </Box>
        </Box>
      ),
      path: `${pathname}?filter=normal`,
    },
    // {
    //   label: t("inventory.type.variant"),
    //   path: `${pathname}?filter=variant`,
    // },
    // {
    //   label: t("inventory.type.bundle"),
    //   path: `${pathname}?filter=bundle`,
    // },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          {t("inventory.type.service")}
          <Box bgcolor={"primary.main"} borderRadius={3} px={0.5} minWidth={30}>
            <Typography color={"white"} fontSize={14}>
              {data?.itemsCount?.service
                ? data?.itemsCount.service > 99999
                  ? "99,9999+"
                  : data?.itemsCount.service.toLocaleString()
                : "0"}
            </Typography>
          </Box>
        </Box>
      ),
      path: `${pathname}?filter=service`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const createOptions = [
    { value: t("inventory.type.normal") },
    // { value: t("inventory.type.variant") },
    // { value: t("inventory.type.bundle") },
    { value: t("inventory.type.service") },
  ];

  const options = [
    {
      value: t("inventory.import_items"),
    },
    {
      value: t("inventory.export_items"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current?.api.setFilterModel({});
    }
  };

  const exportItems = async () => {
    try {
      const { ItemsExportForImport } =
        await graphQLClientWithHeaderItem.request(ITEMS_EXPORT_FOR_IMPORT, {
          exportInput: {
            type: "item_normal",
          },
        });
      window.open(ItemsExportForImport, "_blank", "noopener,noreferrer");
      enqueueSnackbar("นำออกสินค้าสำเร็จ", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("นำออกสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1650,
        }}
      >
        <Box width={"100%"}>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
        <ScanNavigate pathname="inventory/items" />
      </Box>

      <HeaderLayout marginBottom={0}>
        <Typography variant="h5">{t("inventory.items.index")}</Typography>
        <Box>
          <CustomizedMenuOptions
            label={t("inventory.items.add")}
            variant="contained"
            options={createOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerHTML) {
                case t("inventory.type.normal"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "normal" },
                  });
                  break;
                case t("inventory.type.variant"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "variant" },
                  });
                  break;
                case t("inventory.type.bundle"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "bundle" },
                  });
                  break;
                case t("inventory.type.service"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "service" },
                  });
                  break;
                default:
                  break;
              }
            }}
          />
          <CustomizedMenuOptions
            isIcon
            label="item-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `${t("inventory.import_items")}`:
                  navigate(`${pathname}/import`);
                  break;
                case `${t("inventory.export_items")}`:
                  exportItems();
                  break;
                default:
              }
            }}
          />
        </Box>
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.reset_filter")}
          onClick={onFilterReset}
        />
      </Box>
      <ItemsViewTable gridRef={gridRef} isFilter={isFilter} />
    </>
  );
};

export default Items;
