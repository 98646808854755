import { GraphQLClient } from "graphql-request";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ContactEntityType,
  ContactTagsFindByEntityQuery,
  useContactTagsFindByEntityQuery,
} from "../../../generated/contact";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { IContactChannel } from "../../../types/global";
import { Box } from "@mui/material";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { dateFilterParams } from "../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../utils/Formatter/Date";
import { UsersQuery, useUsersQuery } from "../../../generated/company-user";

export const useContactViewColumnDefs = () => {
  const { t } = useTranslation();

  const contactChannelAction = (type: string, info: string) => {
    switch (type) {
      case "เบอร์โทรศัพท์":
      case "แฟกซ์":
        window.open(`tel:${info}`, "_self");
        return;
      case "Email":
        window.open("mailto:" + info, "_self");
        return;
      case "Line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "Instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const graphQLClientWithHeaderUser: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { refetch } =
    useContactTagsFindByEntityQuery<ContactTagsFindByEntityQuery>(
      graphQLClientWithHeaderContact,
      {
        entityName: ContactEntityType.Contact,
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchUser } = useUsersQuery<UsersQuery>(
    graphQLClientWithHeaderUser,
    {},
    {
      enabled: false,
    }
  );

  const colDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "contact_type",
      headerName: t("contact.info.business_type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["บุคคลธรรมดา", "นิติบุคคล"],
      },
      width: 150,
    },
    {
      field: "type",
      headerName: t("contact.contact_type.index"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["is_customer", "is_vendor"],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case "is_customer":
              return t(`contact.contact_type.customer`);
            case "is_vendor":
              return t(`contact.contact_type.vendor`);
            default:
              return "-";
          }
        },
        readOnly: true,
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value.map((value: string) => {
            switch (value) {
              case "is_customer":
                return t(`contact.contact_type.customer`);
              case "is_vendor":
                return t(`contact.contact_type.vendor`);
              default:
                return "-";
            }
          });
        }
      },
      floatingFilter: false,
      width: 150,
    },
    {
      field: "img_url",
      headerName: "รูป",
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              avatars={params.value.map((v: any) => ({
                img_url: v,
                unique_id: params?.data?.unique_id ?? "",
                first_name: params?.data?.name ?? "",
                last_name: params?.data?.last_name ?? "",
              }))}
              sx={{ height: 32, width: 32 }}
            />
          );
        } else
          return (
            <CustomizedAvatar
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.name ?? "",
                  last_name: params?.data?.last_name ?? "",
                },
              ]}
              sx={{ height: 32, width: 32 }}
            />
          );
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
      width: 66.5,
      headerClass: "hide-header-text",
    },
    {
      field: "full_name",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      filter: "agTextColumnFilter",
    },
    {
      field: "identity_no",
      headerName: t("contact.info.taxpayer_id"),
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "contact_channel_list_type",
      headerName: t("contact.contact_channel.index"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams) => {
        const value: IContactChannel[] = params.data.contact_channel_list;
        return value.map((channel, index) => (
          <Box
            sx={{ display: "inline-block", mr: 1 }}
            key={`${channel.contact_channel_name}-${index}`}
          >
            <CustomizedChips
              onClick={() =>
                contactChannelAction(
                  channel.contact_channel_type,
                  channel.contact_channel_name
                )
              }
              value={`${channel.contact_channel_type}: ${channel.contact_channel_name}`}
            />
          </Box>
        ));
      },
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const { data } = await refetch();
          const values = data?.contactTagsFindByEntity;
          const formatValues =
            values && values.length > 0
              ? values
                  .filter((tag) => tag.entity === "contact")
                  .map((tag) => tag.name)
              : [];
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        const tag_list = params.data.tag_list;
        if (tag_list && tag_list.length > 0) {
          return tag_list.map((tag: string, index: number) => (
            <Box key={index} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={tag} value={tag} />
            </Box>
          ));
        }
      },
      width: 160,
    },
    {
      field: "sales_list_text",
      headerName: t("contact.sale_list.index"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          try {
            const { data } = await refetchUser();
            const values = data?.users;
            const userName =
              values && values.length > 0
                ? values.map(
                    (user) =>
                      user?.first_name +
                      (user?.last_name ? " " + user?.last_name : "")
                  )
                : [];
            params.success(userName);
          } catch (err) {
            params.fail();
          }
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        return <CustomizedAvatar avatars={params.data.sales_list} />;
      },
      width: 140,
      cellStyle: {
        display: "flex",
        alignItems: "flex-start",
        justifyContents: "center",
      },
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      width: 160,
    },
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      sort: "desc",
      width: 160,
      hide: true,
    },
  ];

  return colDefs;
};
