import { gql } from "graphql-request";

export const ITEM_SKU_QTY_CHECKER = gql`
  query ItemSkuQtysChecker($findManyInput: ItemSkuQtyFindManyInput) {
    itemSkuQtys(findManyInput: $findManyInput) {
      available_qty
      manufacture_committed_qty
      manufacture_ordered_qty
      purchase_ordered_qty
      sale_committed_qty
      stock_qty
      item_sku_detail {
        item_sku {
          item {
            stock_uom {
              name
            }
          }
        }
      }
      warehouse_level_3 {
        sub_level_2 {
          sub_level_1 {
            warehouse_unique_id
            warehouse {
              name
            }
          }
        }
      }
    }
  }
`;
