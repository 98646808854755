import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useFieldArray } from "react-hook-form";
import { ISalesItemList } from "../../../../types/Sales";
import { useSalesItemListHeader } from "../../../../hooks/Sales/use-sales-item-list-header";
import { useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import { useModal } from "../../../../hooks/use-modal";
import ItemTableModal from "../../ItemTableModal";
import { AgGridReact } from "ag-grid-react";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { ISalesItemPriceHistoryList } from "../../../../types/Sales";
import { EntityTypeEnum } from "../../../../generated/creatable";
import SumPreVatAmount from "./SumPreVatAmount";
import SalesReturnItemList from "./SalesReturnItemList";
import ItemListModal from "../../ItemListModal";
import { v4 as uuidv4 } from "uuid";
import ItemRow from "./ItemRow";
import SalesItemPriceHistoryModal from "../../../Form/Sales/SalesItemPriceHistoryModal";

interface Props {
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  documentType: EntityTypeEnum;
  referenceItemList?: ISalesItemList[];
}

type FieldArrayType = {
  item_list: ISalesItemList[];
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const SalesItemList = ({
  control,
  errors,
  disabled,
  documentType,
  getValues,
  setValue,
  referenceItemList,
}: Props) => {
  const gridRef = useRef<AgGridReact<ISkuDetail>>(null);
  const itemPriceHistoryGridRef =
    useRef<AgGridReact<ISalesItemPriceHistoryList>>(null);
  const gridRefItemList = useRef<AgGridReact<ISalesItemList>>(null);
  const headers = useSalesItemListHeader(documentType, disabled);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const [priceUpdateIndex, setPriceUpdateIndex] = useState<number | undefined>(
    undefined
  );
  const [priceUpdateItemUniqueId, setPriceUpdateItemUniqueId] = useState<
    string | undefined
  >(undefined);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    modal: itemPriceHistoryModal,
    openModalHandler: openItemPriceHistoryModal,
    closeModalHandler: closeItemPriceHistoryModal,
  } = useModal();

  const { fields, move, remove, append } = useFieldArray<
    FieldArrayType,
    "item_list",
    "id"
  >({
    control,
    name: "item_list",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const openItemPriceHistoryModalHandler = (
    index: number,
    item_unique_id: string | undefined
  ) => {
    setPriceUpdateIndex(index);
    setPriceUpdateItemUniqueId(item_unique_id);
    setSearchParams({ type: "quotation" });
    openItemPriceHistoryModal();
  };

  const closeItemPriceHistoryModalHandler = () => {
    closeItemPriceHistoryModal();
    searchParams.delete("type");
    setSearchParams(searchParams);
    setPriceUpdateIndex(undefined);
  };

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");
      selectedItems.forEach((item) => {
        if (item.item_sku && item.item_sku.item) {
          let vat_type;
          switch (item.item_sku.item.sale_vat_type) {
            case "0 %":
            case "0":
              vat_type = "0";
              break;
            case "7 %":
            case "7":
              vat_type = "7";
              break;
            default:
              vat_type = "ไม่มี";
          }
          let formatItems: ISalesItemList = {
            unique_id: uuidv4(),
            item_unique_id: item.sku_name,
            item_name: item.item_sku.item.name,
            item_sku_desc: item.item_sku.item.desc,
            tracability: item.item_sku.item.tracability,
            qty: 1,
            discount: 0,
            discount_type: "baht",
            pre_vat_amount: 0,
            pre_discount_amount: 0,
            price_per_unit:
              item.sale_price ?? item.item_sku.item.sale_price ?? 0,
            uom: item.item_sku.item.stock_uom_unique_id,
            remark: "",
            stock_qty: 1,
            vat_percentage: vat_type,
            withholding_tax_type: "ยังไม่ระบุ",
            withholding_tax_value: 0,
            reference_document_type: documentType,
            reference_unique_id: document_unique_id,
            uom_group: item.item_sku.item.uom_group,
            item_img_url: item.img_url,
            volume: item.item_sku.item.physical_attribute.volume,
            weight: item.item_sku.item.physical_attribute.weight,
            barcode: item.barcode || undefined,
          };
          append(formatItems);
        }
      });
    }
    closeItemModalHandler();
  };

  const addItemPriceFromHistoryHandler = () => {
    const selectedItems =
      itemPriceHistoryGridRef?.current?.api.getSelectedRows();
    if (selectedItems && selectedItems.length > 0) {
      setValue(
        `item_list[${priceUpdateIndex}].price_per_unit`,
        selectedItems?.[0].price_per_unit
      );
    }

    closeItemPriceHistoryModalHandler();
  };

  const addReferenceItemsHandler = () => {
    const selectedItems = gridRefItemList?.current?.api.getSelectedRows();
    if (selectedItems) {
      append(selectedItems);
    }
    closeItemModalHandler();
  };

  if (documentType === EntityTypeEnum.SalesReturn) {
    return (
      <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
        <SalesReturnItemList
          control={control}
          disabled={disabled}
          dragEndHandler={dragEndHandler}
          errors={errors}
          fields={fields}
          getValues={getValues}
          headers={headers}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          setValue={setValue}
          vatOptions={vatOptions}
        />
        <Grid container p={3}>
          <Grid item xs={5}>
            {!disabled && (
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
            )}
          </Grid>
          <Grid item xs={7}>
            <SumPreVatAmount control={control} />
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <ItemListModal
          gridRef={gridRefItemList}
          itemModal={itemModal}
          closeItemModalHandler={closeItemModalHandler}
          addItemsHandler={addReferenceItemsHandler}
          documentType="sales_return"
          itemList={referenceItemList}
          disabled={disabled}
        />
      </CustomizedBox>
    );
  }

  return (
    <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <ItemRow
                      control={control}
                      disabled={disabled}
                      documentType={documentType}
                      errors={errors}
                      field={field}
                      getValues={getValues}
                      headers={headers}
                      index={index}
                      openDeleteItemConfirmationHandler={
                        openDeleteItemConfirmationHandler
                      }
                      openItemPriceHistoryModalHandler={
                        openItemPriceHistoryModalHandler
                      }
                      setValue={setValue}
                      key={field.id}
                    />
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Grid container p={3}>
        <Grid item xs={5}>
          {!disabled && (
            <CustomizedButton
              title="เพิ่มสินค้า"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={openItemModalHandler}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
      <SalesItemPriceHistoryModal
        gridRef={itemPriceHistoryGridRef}
        control={control}
        open={itemPriceHistoryModal}
        closeModalHandler={closeItemPriceHistoryModalHandler}
        addPriceHandler={addItemPriceFromHistoryHandler}
        itemUniqueId={priceUpdateItemUniqueId}
        disabled={disabled}
      />
    </CustomizedBox>
  );
};

export default SalesItemList;
