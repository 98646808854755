import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckbox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ActiveStatus from "./ActiveStatus";
import TagList from "../../../UI/TagList/InventoryTagList";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { ItemEntityType } from "../../../../generated/inventory";

interface ComboBoxProps {
  name: string;
  label: string;
  isDisabled?: boolean;
  error?: any;
  multiline?: boolean;
  required: boolean;
  testId?: string;
  xs?: number;
  sm?: number;
  md: number;
  lg?: number;
  xl?: number;
}

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
  getID?: () => void;
};

const InformationNormal = ({
  control,
  errors,
  getValues,
  setValue,
  disabled,
  isInventoryPage,
  getID,
}: ExtendedProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const itemType = useWatch({ control, name: "type" });

  const renderTextField = (
    name: ComboBoxProps["name"],
    label: ComboBoxProps["label"],
    isDisabled: ComboBoxProps["isDisabled"],
    testId: ComboBoxProps["testId"],
    error: ComboBoxProps["error"],
    required: ComboBoxProps["required"],
    multiline?: boolean
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedTextField
          {...field}
          required={required}
          label={label}
          disabled={isDisabled}
          error={Boolean(error)}
          helperText={error?.message}
          testId={testId}
          multiline={multiline}
          minRows={multiline ? 1 : undefined}
          maxRows={multiline ? 10 : undefined}
          onChange={(e) => {
            const inputValue = e.target.value;
            const lines = inputValue.split("\n");
            if (lines.length <= 10) {
              field.onChange(inputValue);
            }
          }}
        />
      )}
    />
  );

  const renderCheckBox = (
    name: ComboBoxProps["name"],
    label: ComboBoxProps["label"],
    isDisabled: ComboBoxProps["isDisabled"],
    testId: ComboBoxProps["testId"]
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckboxes
          testId={testId}
          label={label}
          {...field}
          isDisabled={isDisabled}
        />
      )}
    />
  );

  const firstTextField: ComboBoxProps[] = [
    {
      name: "unique_id",
      testId: "item-document-text-field",
      label: t("inventory.items.unique_id"),
      required: true,
      sm: 12,
      md: 4,
      error: errors.unique_id,
    },
    {
      name: "name",
      testId: "item-name-text-field",
      label: t("inventory.items.name"),
      required: true,
      sm: 12,
      md: 4,
      error: errors.name,
    },
    {
      name: "seller_sku_name",
      testId: "item-sku-text-field",
      label: t("inventory.items.seller_sku"),
      required: false,
      sm: 12,
      md: 4,
      error: errors.seller_sku_name,
    },
    {
      name: "barcode",
      testId: "item-barcode-text-field",
      label: t("inventory.barcode"),
      required: false,
      sm: 12,
      md: 4,
      error: errors.barcode,
    },
    {
      name: "desc",
      testId: "item-description-text-field",
      label: t("inventory.items.desc"),
      md: ["normal", "service"].includes(itemType) ? 12 : 8,
      required: false,
      error: errors.desc,
      multiline: true,
    },
  ];

  const textFieldOptions = () => {
    switch (itemType) {
      case "normal":
        firstTextField.splice(3, 1);
        return firstTextField;
      case "variant":
        firstTextField.splice(2, 2);
        return firstTextField;
      case "bundle":
        return firstTextField;
      case "service":
        firstTextField.splice(3, 1);
        return firstTextField;
      default:
        firstTextField.splice(3, 1);
        return firstTextField;
    }
  };

  const checkBoxField = [
    {
      testId: "item-is-purchase-check-box",
      name: "is_purchasable",
      label: t("inventory.items.purchaseItem"),
    },
    {
      testId: "item-is-sales-check-box",
      name: "is_saleable",
      label: t("inventory.items.salesItem"),
    },
    {
      testId: "item-is-stock-check-box",
      name: "is_stockable",
      label: t("inventory.items.stockItem"),
      disabled: id ? true : false,
      // item.is_stockable,
    },
    {
      testId: "item-is-manufacture-check-box",
      name: "is_manufactured",
      label: t("inventory.items.manufactuarItem"),
    },
  ];

  const chackBoxOptions = () => {
    switch (itemType) {
      case "normal":
        return checkBoxField;
      case "variant":
        return checkBoxField;
      case "bundle":
        checkBoxField.splice(2, 2);
        return checkBoxField;
      case "service":
        return checkBoxField.splice(0, 2);
      default:
        return checkBoxField;
    }
  };

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item>
          <Grid container spacing={2}>
            {isInventoryPage && (
              <Grid item xs={12}>
                <Typography fontWeight="bold">
                  {t("inventory.items.desc")}
                </Typography>
              </Grid>
            )}
            {textFieldOptions().map((field) => {
              const isDisabled = field.name === "unique_id";
              return (
                <Fragment key={field.label}>
                  <Grid
                    item
                    xs={field.xs}
                    sm={field.sm}
                    md={field.md}
                    lg={field.lg}
                    xl={field.xl}
                  >
                    <Box display={"flex"}>
                      {renderTextField(
                        field.name,
                        field.label,
                        (isDisabled && (Boolean(id) || disabled)) ||
                          !isInventoryPage ||
                          disabled,
                        field.testId,
                        field.error,
                        field.required,
                        field.multiline
                      )}
                      {field.name === "unique_id" && !id && (
                        <CustomizedTooltip title="เรียกรหัสสินค้าใหม่">
                          <IconButton
                            id="item-btn-reload-unique-id"
                            data-test-id="item-btn-reload-unique-id"
                            onClick={getID}
                            sx={{
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <RestartAltOutlinedIcon />
                          </IconButton>
                        </CustomizedTooltip>
                      )}
                    </Box>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        {chackBoxOptions().map((field) => (
          <Grid item key={field.name + field.label}>
            {renderCheckBox(
              field.name,
              field.label,
              field.disabled || !isInventoryPage || disabled,
              field.testId
            )}
          </Grid>
        ))}
      </Grid>
      <ActiveStatus
        control={control}
        isInventoryPage
        disabled={disabled}
        setValue={setValue}
        errors={errors}
        getValues={getValues}
      />
      {itemType !== "bundle" && (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Typography fontWeight="bold" my={2}>
              {t("inventory.items.group_tag_list")}
            </Typography>
          </Grid>
          <TagList
            control={control}
            disabled={disabled}
            setValue={setValue}
            getValues={getValues}
            name="tag_list"
            entity={ItemEntityType.Item}
          />
        </Grid>
      )}
    </Box>
  );
};

export default InformationNormal;
