import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ActivityType,
  ContactActivityLogDocumentType,
  ValidateContactImportQuery,
  useImportContactsMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import {
  formatString,
  formatNumber,
  formatFloat,
  formatInt,
} from "../../../utils/dataTransformer";
import { IAddress, ICredit, IFinance } from "../../../types/Setting/company";
import {
  addImporterErrorHandler,
  findNoneBoolean,
  findNoneBranchType,
  findNoneContactType,
  findNoneContactTypeDetail,
  findNoneContactTypeDetailForIndividual,
  findNoneContactTypeDetailForJuristicPerson,
  findNoneCountry,
  findNoneSource,
  findNoneStatus,
  findNoneTitleName,
  mapNestedData,
} from "../../../utils/Importer";
import { useActivityLog } from "../../use-activity-log";
import { IImporterError } from "../../../types/global";
import { useTranslation } from "react-i18next";

const columns = [
  "unique_id",
  "is_customer",
  "is_vendor",
  "contact_type",
  "contact_type_detail",
  "title_name",
  "main_contact_name",
  "secondary_contact_name",
  "identity_no",
  "registered_capital",
  "contact_source",
  "branch_type",
  "branch_id",
  "branch_name",
  "is_active",
  "status_remark",
  "billing_address",
  "billing_address_sub_district",
  "billing_address_district",
  "billing_address_province",
  "billing_address_postal_code",
  "billing_address_country",
  "is_cheque",
  "is_transfer",
  "is_cash",
  "payment_day",
  "payment_contact_name",
  "payment_contact_phone",
  "credit_count",
  "billing_day",
  "billing_contact_name",
  "billing_contact_phone",
  "credit_limit_value",
  "credit_limit_day",
  "account_payable_id",
  "account_receivable_id",
  "sale_unique_id",
  "remark",
];

const formatBoolean = (value: string) => {
  const trmmedValue = formatString(value);
  switch (trmmedValue) {
    case "ใช่":
      return true;
    case "ไม่ใช่":
      return false;
    default:
      return true;
  }
};

const findDuplicatesWithList = (array: any[]) => {
  const countMap = new Map();
  const duplicates = [];

  for (const item of array) {
    const count = (countMap.get(item) || 0) + 1;
    countMap.set(item, count);
  }

  for (const item of array) {
    const count = countMap.get(item);
    if (count && count >= 2) {
      duplicates.push(item);
    }
  }

  return duplicates;
};

const count_limit = 5000;

const useNormalContactImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();
  const { createActivityLog } = useActivityLog();

  const requiredKeys = [
    {
      label: "รหัสผู้ติดต่อ",
      value: "unique_id",
    },
    {
      label: "ลูกค้า",
      value: "is_customer",
    },
    {
      label: "ผู้ขาย",
      value: "is_vendor",
    },
    {
      label: "ประเภทกิจการ",
      value: "contact_type",
    },
    {
      label: "ชื่อประเภทกิจการ",
      value: "contact_type_detail",
    },
    {
      label: "ชื่อกิจการ/ชื่อจริง",
      value: "main_contact_name",
    },
    {
      label: "ประเภทสำนักงาน",
      value: "branch_type",
    },
    // {
    //   label: "รหัสสาขา",
    //   value: "branch_id",
    // },
    {
      label: "สถานะ",
      value: "is_active",
    },
  ];
  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);
  const [userSaleUniqueIdList, setuserSaleUniqueIdList] = useState<string[]>(
    []
  );

  const graphqlClient = createGraphQLClientWithMiddleware("contact");
  const graphqlClientCompany =
    createGraphQLClientWithMiddleware("company-user");

  const { refetch: validateContact, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_mode: getValues("type"),
          contact_unique_id_list: contactUniqueIdList,
          tag_list: [],
          user_sale_unique_id_list: userSaleUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportContactsMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const invalidTypeErrors: any[] = [];
      const invalidContactTypeErrors: any[] = [];
      const formattedDataSnapshot = [...data];
      const groupDataErrors: any[] = [];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
        const valiationFields = {
          intFields: [
            {
              field: "วันชำระเงิน (วัน)",
              value: formatInt(formattedDataSnapshot[i].payment_day),
            },
            {
              field: "วันรับวางบิล (วัน)",
              value: formatInt(formattedDataSnapshot[i].billing_day),
            },
            {
              field: "เครดิตชำระเงิน (วัน)",
              value: formatInt(formattedDataSnapshot[i].credit_count),
            },
            {
              field: "จำนวนวันที่ต้องการขอ (วัน)",
              value: formatInt(formattedDataSnapshot[i].credit_limit_day),
            },
          ],
          numberFields: [
            {
              field: "ทุนจดทะเบียน (บาท)",
              value: formattedDataSnapshot[i].registered_capital,
            },
            {
              field: "วงเงินเครดิตที่ต้องการขอ (บาท)",
              value: formattedDataSnapshot[i].credit_limit_value,
            },
          ],
        };
        valiationFields.numberFields.forEach(({ field, value }) => {
          if (
            value != null &&
            (isNaN(value) || value < 0 || value >= 1000000000)
          ) {
            invalidTypeErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });
        valiationFields.intFields.forEach(({ field, value }) => {
          if (
            value != null &&
            (!Number.isInteger(value) || value < 0 || value >= 1000000000)
          ) {
            invalidTypeErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });
        const isCustomer = formattedDataSnapshot[i].is_customer;
        const isVendor = formattedDataSnapshot[i].is_vendor;
        if (!formatBoolean(isCustomer) && !formatBoolean(isVendor)) {
          if (isCustomer) {
            invalidContactTypeErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "invalid",
              field: "ลูกค้า",
              detail: isCustomer,
            });
          }
          if (isVendor) {
            invalidContactTypeErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "invalid",
              field: "ผู้ขาย",
              detail: isVendor,
            });
          }
        }

        const branchType = formattedDataSnapshot[i].branch_type;
        const contactType = formattedDataSnapshot[i].contact_type;
        const contactTypeDetail = formattedDataSnapshot[i].contact_type_detail;
        const status = formattedDataSnapshot[i].is_active;

        if (findNoneContactType([contactType]).length === 0) {
          if (contactType === "นิติบุคคล") {
            const noneContactTypeDetail =
              findNoneContactTypeDetailForJuristicPerson([contactTypeDetail]);
            if (
              findNoneContactTypeDetail([contactTypeDetail]).length === 0 &&
              noneContactTypeDetail.length > 0
            ) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: contactTypeDetail,
              });
            }
          } else if (contactType === "บุคคลธรรมดา") {
            const noneContactTypeDetail =
              findNoneContactTypeDetailForIndividual([contactTypeDetail]);
            if (
              findNoneContactTypeDetail([contactTypeDetail]).length === 0 &&
              noneContactTypeDetail.length > 0
            ) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: contactTypeDetail,
              });
            }
          }
        }
        if (
          findNoneBranchType([branchType]).length === 0 &&
          branchType === "สาขา" &&
          !formattedDataSnapshot[i].branch_id
        ) {
          groupDataErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "invalid",
            field: "รหัสสาขา",
          });
        }
        // if (findNoneStatus([status]).length === 0 && status !== "ใช้งาน" && !formattedDataSnapshot[i].status_remark) {
        //   groupDataErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "หมายเหตุสถานะ",
        //   })
        // }
      }

      if (invalidContactTypeErrors.length > 0) {
        invalidContactTypeErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ประเภทผู้ติดต่อ ต้องระบุอย่างน้อย 1 ประเภท",
            error.field,
            [error.detail]
          );
        });
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็น",
            error.field,
            [""]
          );
        });
      }

      if (groupDataErrors.length > 0) {
        groupDataErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ข้อมูลชุดไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      if (invalidTypeErrors.length > 0) {
        invalidTypeErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "รูปแบบข้อมูลไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      const uniqueIdList: string[] = [];
      const userSaleUniqueIdList: string[] = [];
      const customerList: string[] = [];
      const vendorList: string[] = [];
      const contactTypeList: string[] = [];
      const contactTypeDetailList: string[] = [];
      const titleNameList: string[] = [];
      const contactSourceList: string[] = [];
      const branchTypeList: string[] = [];
      const statusList: string[] = [];
      const chequeList: string[] = [];
      const cashList: string[] = [];
      const transferList: string[] = [];
      const countryList: string[] = [];

      const formattedData = await Promise.all(
        data.map(async (contact: any) => {
          const {
            unique_id,
            sale_unique_id,
            is_customer,
            is_vendor,
            contact_type,
            contact_type_detail,
            title_name,
            contact_source,
            branch_type,
            is_active,
            is_cheque,
            is_cash,
            is_transfer,
            billing_address_country,
          } = contact;

          if (unique_id) {
            uniqueIdList.push(unique_id);
          }
          if (sale_unique_id) {
            userSaleUniqueIdList.push(sale_unique_id);
          }
          if (is_customer) {
            customerList.push(is_customer);
          }
          if (is_vendor) {
            vendorList.push(is_vendor);
          }
          if (contact_type) {
            contactTypeList.push(contact_type);
          }
          if (contact_type_detail) {
            contactTypeDetailList.push(contact_type_detail);
          }
          if (title_name) {
            titleNameList.push(title_name);
          }
          if (contact_source) {
            contactSourceList.push(contact_source);
          }
          if (branch_type) {
            branchTypeList.push(branch_type);
          }
          if (is_active) {
            statusList.push(is_active);
          }
          if (is_cheque) {
            chequeList.push(is_cheque);
          }
          if (is_cash) {
            cashList.push(is_cash);
          }
          if (is_transfer) {
            transferList.push(is_transfer);
          }
          if (billing_address_country) {
            countryList.push(billing_address_country);
          }

          const address_list: IAddress[] = [
            {
              address_type: "ที่อยู่จดทะเบียน",
              is_default: true,
              address_contact_name: "",
              address_contact_phone: "",
              address: formatString(contact.billing_address),
              sub_district: formatString(contact.billing_address_sub_district),
              district: formatString(contact.billing_address_district),
              province: formatString(contact.billing_address_province),
              postal_code: formatString(contact.billing_address_postal_code),
              country: formatString(contact.billing_address_country),
            },
          ];
          //To do: change to call api
          const user_sales_list = sale_unique_id
            ? await (async () => {
                try {
                  const response = await graphqlClientCompany.request(
                    `query GetUserId($unique_id: String!) {
                user(uniqueInput: { unique_id: $unique_id }) {
                  id
                  unique_id
                  email
                  first_name
                  img_url
                  last_name
                  position
                }
              }`,
                    { unique_id: sale_unique_id }
                  );

                  return {
                    id: response?.user?.id || 0,
                    unique_id: response?.user?.unique_id || undefined,
                    email: response?.user?.email || undefined,
                    first_name: response?.user?.first_name || undefined,
                    img_url: response?.user?.img_url || [],
                    last_name: response?.user?.last_name || undefined,
                    position: response?.user?.position || undefined,
                  };
                } catch (err) {
                  console.error(
                    `Error fetching sale user data for ${sale_unique_id}`,
                    err
                  );
                  return undefined;
                }
              })()
            : undefined;

          let source: string = "";

          if (contact.contact_source) {
            const sourceMapping: { [key: string]: string } = {
              Sales: "sales",
              Facebook: "facebook",
              Instagram: "instagram",
              Website: "website",
              Email: "email",
              "Line@": "line@",
              "โทรศัพท์ call in": "โทรศัพท์ call in",
              Marketplace: "marketplace",
              อื่นๆ: "อื่นๆ",
            };

            source = sourceMapping[contact.contact_source] || "";
          }

          const request_credit: ICredit = {
            credit_limit_day: formatFloat(contact.credit_limit_day),
            credit_limit_value: formatFloat(contact.credit_limit_value),
          };

          const finance: IFinance = {
            account_payable_id: formatString(contact.account_payable_id),
            account_receivable_id: formatString(contact.account_receivable_id),
            bank_list: [],
            billing_contact_name: formatString(contact.billing_contact_name),
            billing_contact_phone: formatString(contact.billing_contact_phone),
            billing_day: formatFloat(contact.billing_day),
            credit_count: formatString(contact.credit_count),
            is_cash: contact.is_cash === "ใช่" ? true : false,
            is_cheque: contact.is_cheque === "ใช่" ? true : false,
            is_transfer: contact.is_transfer === "ใช่" ? true : false,
            payment_contact_name: formatString(contact.payment_contact_name),
            payment_contact_phone: formatString(contact.payment_contact_phone),
            payment_day: formatString(contact.payment_day),
            request_credit,
          };

          const mappedData = {
            unique_id: formatString(contact.unique_id),
            is_customer: formatBoolean(contact.is_customer),
            is_vendor: formatBoolean(contact.is_vendor),
            contact_type: formatString(contact.contact_type),
            contact_type_detail: formatString(contact.contact_type_detail),
            title_name: contact.title_name
              ? formatString(contact.title_name)
              : "",
            main_contact_name: formatString(contact.main_contact_name),
            secondary_contact_name: formatString(
              contact.secondary_contact_name
            ),
            identity_no: formatString(contact.identity_no),
            registered_capital: formatInt(contact.registered_capital),
            contact_source: source,
            branch_type:
              contact.branch_type === "สำนักงานใหญ่"
                ? "hq"
                : contact.branch_type === "สาขา"
                ? "branch"
                : contact.branch_type === "ไม่ระบุ"
                ? "none"
                : "none",
            // branch_id: contact.branch_id ? formatString(contact.branch_id) : "",
            branch_id:
              contact.branch_type !== "สาขา"
                ? ""
                : formatString(contact.branch_id),
            branch_name:
              contact.branch_type !== "สาขา"
                ? ""
                : formatString(contact.branch_name),
            is_active: contact.is_active === "ใช้งาน" ? 1 : 0,
            status_remark: formatString(contact.status_remark),
            address_list,
            finance,
            sales_list: user_sales_list || undefined,
            remark: formatString(contact.remark),
          };

          return mappedData;
        })
      );

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneCustomer = findNoneBoolean(customerList);
      const noneVendor = findNoneBoolean(vendorList);
      const noneCheque = findNoneBoolean(chequeList);
      const noneTransfer = findNoneBoolean(transferList);
      const noneCash = findNoneBoolean(cashList);
      const noneContactType = findNoneContactType(contactTypeList);
      const noneContactTypeDetail = findNoneContactTypeDetail(
        contactTypeDetailList
      );
      const noneStatus = findNoneStatus(statusList);
      const noneBranchType = findNoneBranchType(branchTypeList);
      const noneTitleName = findNoneTitleName(titleNameList);
      const noneSource = findNoneSource(contactSourceList);
      const noneCountry = findNoneCountry(countryList);

      const allError = [
        duplicateUniqueId,
        requiredFieldErrors,
        invalidTypeErrors,
        noneCustomer,
        noneVendor,
        noneCheque,
        noneTransfer,
        noneCash,
        noneContactType,
        noneContactTypeDetail,
        noneStatus,
        noneBranchType,
        groupDataErrors,
        noneTitleName,
        noneSource,
        noneCountry,
      ];

      addImporterErrorHandler(
        errorData,
        `ข้อมูลในไฟล์ซ้ำกัน`,
        `${t(`contact.document_id`)}`,
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ลูกค้า",
        noneCustomer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ผู้ขาย",
        noneVendor
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เช็ค (การชำระเงิน)",
        noneCheque
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "โอนเงิน (การชำระเงิน)",
        noneTransfer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เงินสด (การชำระเงิน)",
        noneCash
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทกิจการ",
        noneContactType
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ชื่อประเภทกิจการ",
        noneContactTypeDetail
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "คำนำหน้า",
        noneTitleName
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "แหล่งที่มาผู้ติดต่อ",
        noneSource
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเทศที่อยู่จดทะเบียน",
        noneCountry
      );
      setErrorData(errorData);
      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setContactUniqueIdList(Array.from(new Set(uniqueIdList)));
      setuserSaleUniqueIdList(Array.from(new Set(userSaleUniqueIdList)));

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมูล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      if (!tempDoc || tempDoc.length === 0) {
        return;
      }
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else if (tempDoc.length > 0) {
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      //TODO: Validate
      const { data } = await validateContact();
      const existingUniqueId =
        data?.ValidateContactImport.existing_contact_unique_id_list || [];
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];
      const missingUserSaleUniqueId =
        data?.ValidateContactImport.missing_user_sale_unique_id_list || [];
      const arrays = [
        existingUniqueId,
        missingUniqueId,
        missingUserSaleUniqueId,
      ];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        `ข้อมูลซ้ำกับในระบบ`,
        `${t(`contact.document_id`)}`,
        existingUniqueId
      );

      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `รหัส${t(`sales.sales_contact_list`)}`,
        missingUserSaleUniqueId
      );

      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `${t(`contact.document_id`)}`,
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: getValues("type"),
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });

          for (const contact of rowData) {
            const response = await graphqlClient.request(
              `query GetContactId($unique_id: String!) {
                    contact(uniqueInput: { unique_id: $unique_id }) {
                        id
                    }
                }`,
              { unique_id: contact.unique_id }
            );
            const contactId = response?.contact?.id;
            if (contactId) {
              await createActivityLog({
                activity_type: ActivityType.CreateImporter,
                document_type: ContactActivityLogDocumentType.Contact,
                reference_id: contactId,
                activity_detail: {},
              });
            }
          }
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          for (const contact of rowData) {
            const response = await graphqlClient.request(
              `query GetContactId($unique_id: String!) {
                    contact(uniqueInput: { unique_id: $unique_id }) {
                        id
                    }
                }`,
              { unique_id: contact.unique_id }
            );
            const contactId = response?.contact?.id;
            if (contactId) {
              await createActivityLog({
                activity_type: ActivityType.CreateImporter,
                document_type: ContactActivityLogDocumentType.Contact,
                reference_id: contactId,
                activity_detail: {},
              });
            }
          }
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};

export default useNormalContactImporter;
