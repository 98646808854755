import dayjs from "dayjs";

export const formatDate = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatTime = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("HH : mm");
};

export const formatDateTime = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY HH:mm");
};

export const formatDateTimeNoAMPM = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY HH:mm");
};

export const formatDateTimeWithSeconds = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
};

export const formatDateExport = (date?: Date | null) => {
  return dayjs(date).format("DD-MM-YYYY");
};

export const formatDateAgGrid = (date?: Date | null) => {
  return dayjs(date).format("YYYY-MM-DD");
};
