import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray } from "react-hook-form";
import TocIcon from "@mui/icons-material/Toc";
import ItemNameCell from "./ItemNameCell";
import { IPurchaseItemList, IPurchaseItemPriceHistoryList } from "../../../../types/Purchase";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { usePurchaseItemListHeader } from "../../../../hooks/Purchase/use-purchase-item-list-header";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import PurchaseRequestItemList from "./PurchaseRequestItemList";
import { AgGridReact } from "ag-grid-react";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { useModal } from "../../../../hooks/use-modal";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import ItemTableModal from "../../ItemTableModal";
import StockQtyCell from "./StockQtyCell";
import { formatNumber } from "../../../../utils/dataTransformer";
import SumPreVatAmount from "./SumPreVatAmount";
import PurchaseReturnItemList from "./PurchaseReturnItemList";
import ItemListModal from "../../ItemListModal";
import { EntityTypeEnum } from "../../../../generated/creatable";
import CheckedPurchaseRequestModal from "../../CheckedPurchaseRequestModal";
import { itemListFormatter } from "../../../../utils/Formatter/Global";
import {
  AdditionalDiscountType,
  PurchaseDocumentType,
} from "../../../../generated/purchase";
import { useSearchParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PurchaseItemPriceHistoryModal from "../../../Form/Purchase/PurchaseItemPriceHistoryModal";

interface Props {
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm<any>["errors"];
  disabled: IDefaultForm["disabled"];
  documentType: EntityTypeEnum;
  referenceItemList?: IPurchaseItemList[];
}

type FieldArrayType = {
  item_list: IPurchaseItemList[];
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const additionalDiscountTypeOptions: ISelectOption[] = [
  {
    label: "บาท",
    value: AdditionalDiscountType.Baht,
  },
  {
    label: "%",
    value: AdditionalDiscountType.Percent,
  },
];

const PurchaseItemList = ({
  control,
  errors,
  disabled,
  documentType,
  getValues,
  setValue,
  referenceItemList,
}: Props) => {
  const gridRef = useRef<AgGridReact<ISkuDetail>>(null);
  const itemPriceHistoryGridRef =
    useRef<AgGridReact<IPurchaseItemPriceHistoryList>>(null);
  const gridRefItemList = useRef<AgGridReact<IPurchaseItemList>>(null);
  const headers = usePurchaseItemListHeader(documentType, disabled);
  const [searchParams, setSearchParams] = useSearchParams();

  const [purchaseRequestModel, setPurchaseRequestModal] =
    useState<boolean>(false);
  const [purchaseRequestIds, setPurchaseRequestIds] = useState<string[]>([]);
  const [purchaseRequestIdsSnapshot, setPurchaseRequestIdsSnapshot] = useState<
    string[]
  >([]);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const [priceUpdateIndex, setPriceUpdateIndex] = useState<number | undefined>(
    undefined
  );
  const [priceUpdateItemUniqueId, setPriceUpdateItemUniqueId] = useState<
    string | undefined
  >(undefined);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    modal: itemPriceHistoryModal,
    openModalHandler: openItemPriceHistoryModal,
    closeModalHandler: closeItemPriceHistoryModal,
  } = useModal();

  const { fields, move, remove, append } = useFieldArray<
    FieldArrayType,
    "item_list",
    "id"
  >({
    control,
    name: "item_list",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const openItemPriceHistoryModalHandler = (
    index: number,
    item_unique_id: string | undefined
  ) => {
    setPriceUpdateIndex(index);
    setPriceUpdateItemUniqueId(item_unique_id);
    setSearchParams({ type: "purchase_order" });
    openItemPriceHistoryModal();
  };

  const closeItemPriceHistoryModalHandler = () => {
    closeItemPriceHistoryModal();
    searchParams.delete("type");
    setSearchParams(searchParams);
    setPriceUpdateIndex(undefined);
  };

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");
      selectedItems.forEach((item) => {
        if (item.item_sku && item.item_sku.item) {
          let vat_type;
          switch (item.item_sku.item.purchase_vat_type) {
            case "0 %":
            case "0":
              vat_type = "0";
              break;
            case "7 %":
            case "7":
              vat_type = "7";
              break;
            default:
              vat_type = "ไม่มี";
          }
          let formatItems: IPurchaseItemList = {
            item_unique_id: item.sku_name,
            item_name: item.item_sku.item.name,
            item_sku_desc: item.item_sku.item.desc,
            qty: 1,
            discount: 0,
            discount_type: "baht",
            pre_vat_amount: 0,
            pre_discount_amount: 0,
            price_per_unit: item.item_sku.item.purchase_standard_price ?? 0,
            uom: item.item_sku.item.stock_uom_unique_id,
            remark: "",
            stock_qty: 1,
            vat_percentage: vat_type,
            withholding_tax_type: "ยังไม่ระบุ",
            withholding_tax_value: 0,
            reference_document_type: documentType,
            reference_unique_id: document_unique_id,
            uom_group: item.item_sku.item.uom_group,
            qty_ordered: 0,
            qty_received: 0,
            qty_returned: 0,
            po_qty: 0,
            item_img_url: item.img_url,
            tracability: item.item_sku.item.tracability,
            barcode: item.barcode || undefined,
          };
          append(formatItems);
        }
      });
    }
    closeItemModalHandler();
  };

  const addItemPriceFromHistoryHandler = () => {
    const selectedItems =
      itemPriceHistoryGridRef?.current?.api.getSelectedRows();
    if (selectedItems && selectedItems.length > 0) {
      setValue(
        `item_list[${priceUpdateIndex}].price_per_unit`,
        selectedItems?.[0].price_per_unit
      );
    }

    closeItemPriceHistoryModalHandler();
  };

  const addReferenceItemsHandler = () => {
    const selectedItems = gridRefItemList?.current?.api.getSelectedRows();
    if (selectedItems) {
      append(selectedItems);
    }
    closeItemModalHandler();
  };

  const openPurchaseRequestTable = () => {
    setPurchaseRequestModal(true);
  };

  const closePurchaseRequestTable = () => {
    setPurchaseRequestModal(false);
  };

  const finishPurchaseRequestsSelect = async (data: any[]) => {
    let oldItemList: IPurchaseItemList[] = getValues("item_list");
    let itemList: IPurchaseItemList[] = [];
    let allUniqueId: string[] = [];
    data.forEach((request) => {
      allUniqueId.push(request.unique_id);
      request.item_list.forEach(({ id, ...item }: IPurchaseItemList) => {
        const oldItem = oldItemList.find(
          (oldItem) =>
            oldItem.reference_line_item?.reference_unique_id === item.unique_id
        ) as IPurchaseItemList;
        if (!oldItem)
          itemList.push({
            ...item,
            pr_reference_unique_id: request.unique_id,
            reference_line_item: {
              reference_document_type: PurchaseDocumentType.PurchaseRequest,
              reference_unique_id: item.unique_id,
            },
          });
        else itemList.push(oldItem);
      });
    });

    const fotmattedItemList = await itemListFormatter(itemList);
    setValue("item_list", fotmattedItemList);
    closePurchaseRequestTable();
  };

  useEffect(() => {
    if (documentType === "purchase_order") {
      if (fields && fields.length > 0) {
        const allFiltered = fields.map(
          (item) => item.pr_reference_unique_id
        ) as string[];

        const uniqueIdList = allFiltered.filter((element, index) => {
          return (
            element !== undefined &&
            element !== null &&
            allFiltered.indexOf(element) === index
          );
        });

        setPurchaseRequestIds(uniqueIdList);
        setPurchaseRequestIdsSnapshot(uniqueIdList);
        setValue("reference_unique_id_list", uniqueIdList);
      } else {
        setPurchaseRequestIds([]);
        setPurchaseRequestIdsSnapshot([]);
        setValue("reference_unique_id_list", []);
      }
    }
  }, [documentType, fields, setValue]);

  if (documentType === "purchase_request") {
    return (
      <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
        <PurchaseRequestItemList
          headers={headers}
          fields={fields}
          control={control}
          errors={errors}
          disabled={disabled}
          dragEndHandler={dragEndHandler}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          getValues={getValues}
          setValue={setValue}
        />
        <Grid container p={3}>
          <Grid item xs={5}>
            {!disabled && (
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
            )}
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <ItemTableModal
          ref={gridRef}
          itemModal={itemModal}
          closeItemModalHandler={closeItemModalHandler}
          addItemsHandler={addItemsHandler}
        />
      </CustomizedBox>
    );
  } else if (documentType === "purchase_return") {
    return (
      <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
        <PurchaseReturnItemList
          headers={headers}
          fields={fields}
          control={control}
          errors={errors}
          disabled={disabled}
          dragEndHandler={dragEndHandler}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          getValues={getValues}
          setValue={setValue}
          vatOptions={vatOptions}
        />
        <Grid container p={3}>
          <Grid item xs={5}>
            {!disabled && (
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
            )}
          </Grid>
          <Grid item xs={7}>
            <SumPreVatAmount control={control} />
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <ItemListModal
          gridRef={gridRefItemList}
          itemModal={itemModal}
          closeItemModalHandler={closeItemModalHandler}
          addItemsHandler={addReferenceItemsHandler}
          documentType="purchase_return"
          itemList={referenceItemList}
          disabled={disabled}
        />
      </CustomizedBox>
    );
  }

  return (
    <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      draggableId={"item-" + field.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={disabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>{field.qty}</Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list[${index}].qty`}
                                type="number"
                                error={Boolean(
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.qty
                                )}
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(e, fieldHook) => {
                                  const parseValue = parseInt(e.target.value);
                                  fieldHook.onChange(parseValue);
                                  const currentUomUniqueId = getValues(
                                    `item_list[${index}].uom`
                                  );
                                  if (currentUomUniqueId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomUniqueId ===
                                        field.uom_group.base_uom?.unique_id
                                      ) {
                                        setValue(
                                          `item_list[${index}].stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_unique_id ===
                                                currentUomUniqueId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list[${index}].stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>{field.uom}</Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list[${index}].uom`}
                                error={Boolean(
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.uom
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.uom &&
                                  errors?.item_list[index]?.uom?.message
                                }
                                options={
                                  field.uom_group
                                    ? field.uom_group.convertable_uom_list &&
                                      field.uom_group.convertable_uom_list
                                        .length > 0
                                      ? [
                                        {
                                          label:
                                            field.uom_group?.base_uom?.name ||
                                            "",
                                          value:
                                            field.uom_group?.base_uom
                                              ?.unique_id || "",
                                        },
                                        ...field.uom_group.convertable_uom_list.map(
                                          (uom) => ({
                                            label: uom.name,
                                            value: uom.unique_id,
                                          })
                                        ),
                                      ]
                                      : [
                                        {
                                          label:
                                            field.uom_group?.base_uom?.name ||
                                            "",
                                          value:
                                            field.uom_group?.base_uom
                                              ?.unique_id || "",
                                        },
                                      ]
                                    : []
                                }
                                onChange={(e: any) => {
                                  const currentQty = getValues(
                                    `item_list[${index}].qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.unique_id
                                    ) {
                                      setValue(
                                        `item_list[${index}].stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_unique_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list[${index}].stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Box
                                sx={
                                  documentType === EntityTypeEnum.PurchaseOrder
                                    ? {
                                      display: "flex",
                                      gap: 1,
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }
                                    : undefined
                                }
                              >
                                <Typography fontSize={14}>
                                  {formatNumber(field.price_per_unit)}
                                </Typography>
                                <IconButton
                                  aria-label="view purchase item price history"
                                  size="small"
                                  sx={{ color: "#2167D3" }}
                                  onClick={() =>
                                    openItemPriceHistoryModalHandler(
                                      index,
                                      field.item_unique_id
                                    )
                                  }
                                >
                                  <AccessTimeIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ControlledNumberTextField
                                  control={control}
                                  name={`item_list[${index}].price_per_unit`}
                                  error={Boolean(
                                    errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                  )}
                                />
                                <IconButton
                                  aria-label="view purchase item price history"
                                  size="small"
                                  sx={{ color: "#2167D3" }}
                                  onClick={() =>
                                    openItemPriceHistoryModalHandler(
                                      index,
                                      field.item_unique_id
                                    )
                                  }
                                >
                                  <AccessTimeIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list[${index}].discount`}
                                error={Boolean(
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.discount
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.discount_type === "percent"
                                  ? "%"
                                  : "บาท"}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list[${index}].discount_type`}
                                options={additionalDiscountTypeOptions}
                                onChange={() => {
                                  if (setValue)
                                    setValue(`item_list[${index}].discount`, 0);
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.vat_percentage}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list[${index}].vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.vat_percentage
                                )}
                                helperText={
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.vat_percentage &&
                                  errors?.item_list[index]?.vat_percentage
                                    ?.message
                                }
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={disabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            <WithholdingTaxCell
                              control={control}
                              errors={errors}
                              index={index}
                              documentType={documentType}
                              disabled={disabled}
                              setValue={setValue}
                              field={field}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            <StockQtyCell
                              control={control}
                              index={index}
                              disabled={disabled}
                              field={field}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[10]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Typography fontSize={14}>
                              {field.pr_reference_unique_id || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[11]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list[${index}].remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.remark
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[12]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Typography fontSize={14}>
                              {field.qty_received || 0}
                            </Typography>
                          </TableCell>
                          {!disabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[13]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Grid container p={3}>
        <Grid item xs={5}>
          <Box display="flex" gap={1}>
            {!disabled && (
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
            )}
            {!disabled && documentType === EntityTypeEnum.PurchaseOrder && (
              <CustomizedButton
                title="นำเข้าใบขอซื้อ"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openPurchaseRequestTable}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
      <CheckedPurchaseRequestModal
        showSelectPurchaseRequest={purchaseRequestModel}
        closePurchaseRequestTable={closePurchaseRequestTable}
        finishPurchaseRequestsSelect={finishPurchaseRequestsSelect}
        purchaseRequestIds={purchaseRequestIds}
        purchaseRequestIdsSnapshot={purchaseRequestIdsSnapshot}
        setPurchaseRequestIds={setPurchaseRequestIds}
        setPurchaseRequestIdsSnapshot={setPurchaseRequestIdsSnapshot}
        rowSelection="multiple"
      />
      <PurchaseItemPriceHistoryModal
        gridRef={itemPriceHistoryGridRef}
        control={control}
        open={itemPriceHistoryModal}
        closeModalHandler={closeItemPriceHistoryModalHandler}
        addPriceHandler={addItemPriceFromHistoryHandler}
        itemUniqueId={priceUpdateItemUniqueId}
        disabled={disabled}
      />
    </CustomizedBox>
  );
};

export default PurchaseItemList;
