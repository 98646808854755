import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

const Contact = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: "ทั้งหมด",
      to: "all",
      icon: <ContactsOutlinedIcon color="primary" />,
    },
    {
      name: t("contact.contact_type.customer"),
      to: "customer",
      icon: <GroupOutlinedIcon color="primary" />,
    },
    {
      name: t("contact.contact_type.vendor"),
      to: "vendor",
      icon: <PermContactCalendarOutlinedIcon color="primary" />,
    },
    // {
    //   name: t("report"),
    //   to: "report",
    // },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Contact;
