import { ValueFormatterParams, ColDef } from "ag-grid-community";

import { statusValueFormatter } from "../../../../utils/Formatter/Global";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate, formatDateTime } from "../../../../utils/Formatter/Date";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { formatNumber } from "../../../../utils/dataTransformer";

export const useRoutingColumnDef = (isReport: boolean) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    if (!isReport) {
      setColumnDefs([
        {
          field: "name",
          headerName: t("manufacture.routing.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "item_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "item_name",
          headerName: t("inventory.items.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "mfg_type",
          headerName: t("manufacture.order.type"),
          filter: "agTextColumnFilter",
        },
        {
          field: "internal_remark",
          headerName: t("manufacture.bom.internal_remark"),
          filter: "agTextColumnFilter",
        },
        {
          field: "is_main_routing",
          headerName: t("manufacture.routing.is_main"),
          filterParams: {
            values: ["true", "false"],
            valueFormatter: ({ value }: { value: any }) => {
              if (value === "true") {
                return "ใช่";
              }
              return "ไม่ใช่";
            },
          },
          valueFormatter: ({ value }: { value: number }) => {
            if (value) return "ใช่";
            return "ไม่ใช่";
          },
        },
      ]);
    } else {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("manufacture.routing.unique_id"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "issue_date",
          headerName: t("manufacture.routing.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.issue_date
              ? formatDate(params.data.issue_date)
              : null;
          },
          sort: "desc",
        },
        {
          field: "work_order_id",
          sort: "asc",
          minWidth: 200,
          hide: true,
        },
        {
          field: "status",
          headerName: t("status.index"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
            values: [
              "draft",
              "pending_manu",
              "in_progress",
              "finished",
              "cancelled",
            ],
          },
          cellRenderer: (params: ValueFormatterParams) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            paddingTop: "6px",
          },
          minWidth: 200,
        },
        {
          field: "reference_unique_ids",
          headerName: t("manufacture.order.reference_unique_id_list"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "external_unique_id",
          headerName: t("manufacture.order.external_reference_id"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "production_date",
          headerName: t("manufacture.order.production_date") || "",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.production_date
              ? formatDate(params.data.production_date)
              : null;
          },
          minWidth: 200,
        },
        {
          field: "production_completion_date",
          headerName: t("manufacture.order.production_completion_date") || "",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.production_completion_date
              ? formatDate(params.data.production_completion_date)
              : null;
          },
          minWidth: 200,
        },
        {
          field: "delivery_date",
          headerName: t("manufacture.order.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.delivery_date
              ? formatDate(params.data.delivery_date)
              : null;
          },
          minWidth: 150,
        },
        {
          field: "customer_contact_unique_id",
          headerName: t("manufacture.order.contact_document_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "customer_contact_unique_id",
          headerName: t("manufacture.order.contact_document_id"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "customer_name",
          headerName: t("manufacture.order.customer_name"),
          filter: "agTextColumnFilter",

          minWidth: 250,
        },
        {
          field: "customer_name",
          headerName: t("manufacture.order.customer_name"),
          filter: "agTextColumnFilter",

          minWidth: 250,
        },
        {
          field: "item_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "item_name",
          headerName: t("inventory.items.name"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "production_qty",
          headerName: t("manufacture.order.production_qty_1") || "",
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { direction: "rtl" },
          floatingFilter: false,
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "actual_production_qty",
          headerName: t("manufacture.order.actual_production_qty") || "",
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { direction: "rtl" },
          floatingFilter: false,
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "goods_receive_qty",
          headerName: t("manufacture.order.goods_receive_qty_1"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { direction: "rtl" },
          floatingFilter: false,
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "production_qty",
          headerName: t("manufacture.order.production_qty_1") || "",
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "actual_production_qty",
          headerName: t("manufacture.order.actual_production_qty") || "",
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "goods_receive_qty",
          headerName: t("manufacture.order.goods_receive_qty_1"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "uom",
          headerName: t("manufacture.routing.uom"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "item_description",
          headerName: t("manufacture.order.item_description"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          cellStyle: {
            whiteSpace: "pre-wrap",
            lineHeight: 1.75,
            paddingTop: "9.5px",
            paddingBottom: "9.5px",
          },
          wrapText: true,
          autoHeight: true,
        },
        {
          field: "item_description",
          headerName: t("manufacture.order.item_description"),
          filter: "agTextColumnFilter",

          minWidth: 150,
        },
        {
          field: "item_remark",
          headerName: t("manufacture.routing.goods_remark"),
          filter: "agTextColumnFilter",

          minWidth: 180,
        },
        {
          field: "bom_name",
          headerName: t("manufacture.order.bom"),
          filter: "agTextColumnFilter",
        },
        {
          field: "bom_name",
          headerName: t("manufacture.order.bom"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "routing_name",
          headerName: t("manufacture.routing.rounting_name"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },

        {
          field: "mfg_type",
          headerName: t("manufacture.order.type"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "group",
          headerName: t("manufacture.order.group"),
          filter: "agSetColumnFilter",
          cellRenderer: (params: ValueFormatterParams) =>
            params.value ? (
              <CustomizedChips key={params.value} value={`${params.value}`} />
            ) : (
              ""
            ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 180,
        },
        {
          field: "routing_name",
          headerName: t("manufacture.routing.rounting_name"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },

        {
          field: "mfg_type",
          headerName: t("manufacture.order.type"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "group",
          headerName: t("manufacture.order.group"),
          filter: "agSetColumnFilter",
          cellRenderer: (params: ValueFormatterParams) =>
            params.value ? (
              <CustomizedChips key={params.value} value={`${params.value}`} />
            ) : (
              ""
            ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
        },
        {
          field: "other_manufacture_cost",
          headerName: t("manufacture.order.cost.other_manufacture_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "work_order_cost",
          headerName: t("manufacture.order.cost.work_order_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "bom_cost",
          headerName: t("manufacture.order.cost.bom_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "total_cost",
          headerName: t("manufacture.order.cost.total_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "goods_remark",
          headerName: t("manufacture.bom.internal_remark"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.created_date
              ? formatDate(params.data.created_date)
              : null;
          },
        },
        {
          field: "created_by",
          headerName: t("sentence.created_by"),
          filter: "agTextColumnFilter",

          width: 200,
        },
        {
          field: "related_users",
          headerName: t("sales.employee_list"),
          filter: "agTextColumnFilter",
        },
        {
          field: "created_by",
          headerName: t("sentence.created_by"),
          filter: "agTextColumnFilter",

          width: 200,
        },
        {
          field: "related_users",
          headerName: t("sales.employee_list"),
          filter: "agTextColumnFilter",

          minWidth: 200,
          flex: 1,
        },
        // {
        //   field: "attachment_urls",
        //   headerName: t("manufacture.order.attachment_urls"),
        //   filter: "agTextColumnFilter",

        //   suppressMenu: true,
        //   floatingFilter: false,
        //   sortable: false,
        //   hide: true,
        //   minWidth: 200,
        // },
        {
          field: "attachment_urls",
          headerName: t("manufacture.order.attachment_urls"),
          filter: "agTextColumnFilter",

          suppressMenu: true,
          floatingFilter: false,
          sortable: false,
          hide: true,
          minWidth: 200,
        },
        {
          field: "order",
          headerName: t("manufacture.workOrder.no"),
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          suppressMenu: true,
          floatingFilter: false,
          sortable: false,
          width: 150,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          headerClass: "ag-end-header",
        },
        {
          field: "step_status",
          headerName: t("manufacture.routing.step_status"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
            values: [
              "draft",
              "pending_manu",
              "in_progress",
              "finished",
              "cancelled",
            ],
          },
          cellRenderer: (params: ValueFormatterParams) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            paddingTop: "6px",
          },
          minWidth: 200,
        },
        {
          field: "responsible_users",
          headerName: t("manufacture.routing.responsible_user_list"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "responsible_users",
          headerName: t("manufacture.routing.responsible_user_list"),
          filter: "agTextColumnFilter",

          minWidth: 200,
          flex: 1,
        },
        {
          field: "name",
          headerName: t("manufacture.routing.name-1"),
          filter: "agTextColumnFilter",

          minWidth: 200,
        },
        {
          field: "factory",
          headerName: t("manufacture.routing.plant"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "production_center",
          headerName: t("manufacture.workOrder.production_center"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "duration",
          headerName: t("manufacture.routing.duration"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          suppressMenu: true,
          sortable: false,
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "duration_unit",
          headerName: t("manufacture.routing.unitDuration"),
          filter: "agTextColumnFilter",
          suppressMenu: true,
          sortable: false,
          floatingFilter: false,
          minWidth: 200,
        },
        {
          field: "cost_price",
          headerName:
            t("manufacture.routing.cost_price") +
            ` (${t("manufacture.routing.report_index")})`,
          filter: "adNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { direction: "rtl" },
          suppressMenu: true,
          sortable: false,
          floatingFilter: false,
          minWidth: 200,
          headerClass: "ag-end-header",
        },
        {
          field: "started_date",
          headerName:
            t("manufacture.bom.start_date") +
            ` (${t("manufacture.routing.report_index")})`,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.started_date
              ? formatDateTime(params.data.started_date)
              : null;
          },
          minWidth: 200,
        },
        {
          field: "finished_date",
          headerName:
            t("manufacture.workOrder.finished_date") +
            ` (${t("manufacture.routing.report_index")})`,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => {
            return params.data.finished_date
              ? formatDateTime(params.data.finished_date)
              : null;
          },
          minWidth: 200,
        },
        {
          field: "remark",
          headerName: t("manufacture.routing.remark"),
          filter: "agTextColumnFilter",
          suppressMenu: true,
          sortable: false,
          floatingFilter: false,
          minWidth: 200,
        },
      ]);
    }
  }, [isReport, t]);
  return columnDefs;
};
