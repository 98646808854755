import { Fragment, useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Box, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckbox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import addressData from "../../../data/address.json";
const NewAddress = ({
  control,
  errors,
  setValue,
  getValues,
  update,
  remove,
  disabled,
  itemId,
  index,
  isSameAddress,
}: any) => {
  const [subDistrictOptions, setSubDistrictOptions] = useState<string[]>([]);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<string[]>([]);
  const [searchSubDistrict, setSearchSubDistrict] = useState<
    string | null | undefined
  >("");
  const [searchDistrict, setSearchDistrict] = useState<
    string | null | undefined
  >("");
  const [searchProvince, setSearchProvince] = useState<
    string | null | undefined
  >("");

  const { t } = useTranslation();

  const updateFilter = useCallback(() => {
    let filteredAddressData = addressData;
    if (searchSubDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.sub_district.includes(searchSubDistrict)
      );
    }
    if (searchDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.district.includes(searchDistrict)
      );
    }
    if (searchProvince) {
      filteredAddressData = addressData.filter((item) =>
        item.province.includes(searchProvince)
      );
    }
    let filteredProvinces: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredProvinces.includes(item.province)) {
        filteredProvinces = [...filteredProvinces, item.province];
      }
    });
    setProvinceOptions(filteredProvinces);

    let filteredDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredDistricts.includes(item.district)) {
        filteredDistricts = [...filteredDistricts, item.district];
      }
    });
    setDistrictOptions(filteredDistricts);

    let filteredSubDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredSubDistricts.includes(item.sub_district)) {
        filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
      }
    });
    setSubDistrictOptions(filteredSubDistricts);
  }, [searchDistrict, searchProvince, searchSubDistrict]);

  useEffect(() => {
    updateFilter();
  }, [updateFilter]);

  const addressOptions = [
    {
      id: 1,
      value: "ที่อยู่จัดส่ง",
      label: "ที่อยู่จัดส่ง",
    },
    {
      id: 2,
      value: "ที่อยู่ส่งเอกสาร",
      label: "ที่อยู่ส่งเอกสาร",
    },
  ];

  const countryOptions = ["ไทย"];

  const autofillAddress = (index: number) => {
    const addressList = getValues("address_list");
    const mainAddress = addressList[0];
    const currentAddress = addressList[index];
    const updatedAddress = {
      is_default: false,
      is_same_as_default_address: currentAddress.is_same_as_default_address,
      address_type: currentAddress.address_type,
      address_contact_name: currentAddress.address_contact_name,
      address_contact_phone: currentAddress.address_contact_phone,
      address: mainAddress.address,
      sub_district: mainAddress.sub_district,
      district: mainAddress.district,
      province: mainAddress.province,
      postal_code: mainAddress.postal_code,
      country: mainAddress.country,
    };
    update(index, updatedAddress);
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        backgroundColor: "#ECF6FDCC",
        mb: 2,
        padding: "16px",
      }}
    >
      <Grid container spacing={2} mb={1.5}>
        <Grid item xs={4}>
          <Controller
            key={`address_type-${itemId}`}
            name={`address_list.${index}.address_type`}
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                freeSolo={true}
                options={addressOptions}
                label={t("address.name")}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                // onInputChange will used with free solo
                onInputChange={(e, value) => {
                  if (!value) {
                    return field.onChange("");
                  } else {
                    return field.onChange(value);
                  }
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <Controller
            key={`same_address-${itemId}`}
            name={`address_list.${index}.is_same_as_default_address`}
            control={control}
            render={({ field }) => (
              <CustomizedCheckboxes
                {...field}
                label={"เหมือนที่อยู่จดทะเบียน"}
                onChange={(e, selected) => {
                  if (selected) {
                    autofillAddress(index);
                  }
                  return field.onChange(selected);
                }}
                isDisabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} textAlign="end">
          {!disabled && index !== 0 && (
            <IconButton
              aria-label="delete"
              onClick={() => remove(index)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              key={`address-${itemId}`}
              name={`address_list.${index}.address`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  error={Boolean(errors.address_list?.[index]?.address)}
                  helperText={errors.address_list?.[index]?.address?.message}
                  label={t("address.index")}
                  disabled={disabled || isSameAddress[index]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`sub_district-${itemId}`}
              name={`address_list.${index}.sub_district`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      {...field}
                      error={Boolean(
                        errors.address_list?.[index]?.sub_district
                      )}
                      helperText={
                        errors.address_list?.[index]?.sub_district?.message
                      }
                      label={t("address.sub_district")}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedComboBox
                    {...field}
                    options={subDistrictOptions}
                    label={t("address.sub_district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchSubDistrict(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchSubDistrict(option);
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.sub_district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        let fieldsValue = {
                          ...getValues("address_list")[index],
                          district: filter[0].district,
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`address_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`district-${itemId}`}
              name={`address_list.${index}.district`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      {...field}
                      error={Boolean(errors.address_list?.[index]?.district)}
                      helperText={
                        errors.address_list?.[index]?.district?.message
                      }
                      label={t("address.district")}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedComboBox
                    {...field}
                    options={districtOptions}
                    label={t("address.district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchDistrict(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchDistrict(option);
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        const filteredSubdistricts = filter.map(
                          (address) => address.sub_district
                        );
                        setSubDistrictOptions(filteredSubdistricts);
                        let fieldsValue = {
                          ...getValues("address_list")[index],
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`address_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`province-${itemId}`}
              name={`address_list.${index}.province`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.address_list?.[index]?.province)}
                      helperText={
                        errors.address_list?.[index]?.province?.message
                      }
                      label={t("address.province")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedComboBox
                    {...field}
                    options={provinceOptions}
                    label={t("address.province")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSearchProvince(undefined);
                        updateFilter();
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSearchProvince(option);
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      setValue(`address_list.${index}.country`, "ไทย");
                      return field.onChange(option);
                    }}
                    disabled={disabled || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`postal_code-${itemId}`}
              name={`address_list.${index}.postal_code`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  error={Boolean(errors.address_list?.[index]?.postal_code)}
                  helperText={
                    errors.address_list?.[index]?.postal_code?.message
                  }
                  label={t("address.postal_code")}
                  disabled={disabled || isSameAddress[index]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`country-${itemId}`}
              name={`address_list.${index}.country`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.address_list?.[index]?.country)}
                      helperText={
                        errors.address_list?.[index]?.country?.message
                      }
                      label={t("address.country")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedComboBox
                    {...field}
                    options={countryOptions}
                    label={t("address.country")}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    disabled={disabled || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ my: 2 }}>
          <Divider variant="fullWidth" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`full_name-${itemId}`}
              name={`address_list.${index}.address_contact_name`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  error={Boolean(
                    errors.address_list?.[index]?.address_contact_name
                  )}
                  helperText={
                    errors.address_list?.[index]?.address_contact_name?.message
                  }
                  label={t("address.contact_person_name")}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`phone_number-${itemId}`}
              name={`address_list.${index}.address_contact_phone`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  error={Boolean(
                    errors.address_list?.[index]?.address_contact_phone
                  )}
                  helperText={
                    errors.address_list?.[index]?.address_contact_phone?.message
                  }
                  label={t("address.phone_number")}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewAddress;
