import { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab, IExporter } from "../../types/global";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import HeaderLayout from "../../components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedTab from "../../components/Custom/CustomizedTab";
// import ContactsTable from "../../components/Table/Contacts";
import CustomizedMenuOptions from "../../components/Custom/CustomizedMenuOptions";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  ContactExportInput,
  useExportContactQuery,
  ExportContactQuery,
  ContactExportType,
} from "../../generated/contact";
import ExporterModal from "../../components/UI/ExporterModal";
import { useModal } from "../../hooks/use-modal";
import { contactTypeOptions } from "../../utils/Formatter/Contact";
import ContactViewTable from "../../components/Table/Contacts/ContactViewTable";

type Props = {
  type: "all" | "customer" | "vendor";
};

const Contacts = ({ type }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [exportInput, setExportInput] = useState<ContactExportInput | null>(
    null
  );

  const graphqlClient = createGraphQLClientWithMiddleware("contact");

  const { refetch: refetchData, isLoading: isExporting } =
    useExportContactQuery<ExportContactQuery>(
      graphqlClient,
      {
        exportInput: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        export_type: type as ContactExportType,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.ExportContact || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  const options = [
    {
      value: "นำเข้าผู้ติดต่อ",
    },
    {
      value: "นำออกผู้ติดต่อ",
    },
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t(`contact.contact_type.${type}`),
    },
  ];

  const tabs: ITab[] = [
    {
      label: "ทั้งหมด",
      path: `/contact/all`,
    },
    {
      label: t("contact.contact_type.customer"),
      path: `/contact/customer`,
    },
    {
      label: t("contact.contact_type.vendor"),
      path: `/contact/vendor`,
    },
  ];

  const currentTab = pathname;

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("contact.index")}</Typography>
        <Box>
          <CustomizedButton
            sx={{ mr: 2 }}
            title="สร้างผู้ติดต่อ"
            variant="contained"
            onClick={() => navigate(`/contact/all/add`)}
          />
          <CustomizedMenuOptions
            isIcon
            label="contact-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "นำเข้าผู้ติดต่อ":
                  navigate(`/contact/import`);
                  break;
                case "นำออกผู้ติดต่อ":
                  openModalHandler();
                  break;
                default:
              }
            }}
          />
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      {/* <ContactsTable isFilter={isFilter} isContactPage/> */}
      <ContactViewTable isFilter={type} />
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={contactTypeOptions}
        exportHandler={exportHandler}
      />
    </Fragment>
  );
};

export default Contacts;
