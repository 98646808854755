import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import GoodsReceiveTab from "./GoodsReceiveTab";
import { ISalesReturn } from "../../../types/Sales/salesReturn";
import { useForm, useWatch } from "react-hook-form";
import {
  salesReturnSchema,
  salesReturnValidation,
} from "../../../components/Form/Sales/Return/schema";
import { yupResolver } from "@hookform/resolvers/yup";

const SalesReturnContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);
  const [tabs, setTabs] = useState<ITab[]>([
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.goods_receive.index"),
      path: `${pathname}?tab=goods_receive`,
      disabled: !id,
    },
  ]);
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    reset,
    resetField,
    handleSubmit,
  } = useForm<ISalesReturn>({
    defaultValues: salesReturnSchema,
    resolver: yupResolver(salesReturnValidation),
    mode: "onChange",
  });

  const isEffectStock = useWatch({ control, name: "is_effect_stock" });

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.return.index"),
      to: "/sales/return",
    },
    {
      name: id ?? `${t("button.create")}${t("sales.return.index")}`,
    },
  ];

  useEffect(() => {
    if (isEffectStock) {
      setTabs([
        {
          label: t("sales.document_info"),
          path: `${pathname}`,
        },
        {
          label: "ใบ" + t("inventory.goods_receive.index"),
          path: `${pathname}?tab=goods_receive`,
          disabled: !id,
        },
      ]);
    } else {
      setTabs([
        {
          label: t("sales.document_info"),
          path: `${pathname}`,
        },
        {
          label: "ใบ" + t("inventory.goods_receive.index"),
          path: `${pathname}?tab=goods_receive`,
          disabled: true,
        },
      ]);
    }
  }, [isEffectStock, pathname, id, t]);

  useEffect(() => {
    switch (tab) {
      case "goods_receive":
        setCurrentTab(pathname + "?tab=goods_receive");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_receive":
        return <GoodsReceiveTab />;
      default:
        return (
          <DocumentInfoTab
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            resetField={resetField}
            handleSubmit={handleSubmit}
          />
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </>
  );
};

export default SalesReturnContainer;
