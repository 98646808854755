import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import CheckboxModalTable from "./CheckboxModalTable";
import { useTranslation } from "react-i18next";
import { ColDef, GetRowIdParams, GridReadyEvent } from "ag-grid-community";
import { itemColumnDefs } from "./Inventory/Item/columnDefs";
import { IServerSideDatasource } from "ag-grid-community";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import {
  InventoryTagsFindByEntityQuery,
  ItemEntityType,
  ItemType,
  useInventoryTagsFindByEntityQuery,
} from "../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

type CheckedItemModalProps = {
  showSelectItem: boolean;
  closeItemTable: () => void;
  finishItemsSelect: (data: any) => void;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  rowSelection?: "single" | "multiple";
  noService?: boolean;
};

const CheckedItemModal = ({
  showSelectItem,
  closeItemTable,
  finishItemsSelect,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  isLoading,
  rowSelection,
  noService,
}: CheckedItemModalProps) => {
  const selectItemGridref = useRef();
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: tagList } =
    useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
      graphQLClientWithHeaderItem,
      {
        entityName: ItemEntityType.Item,
      }
    );

  const columnDefs: ColDef[] = itemColumnDefs(
    t,
    false,
    true,
    tagList?.itemTagsFindByEntity ?? [],
    noService
  );

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        type,
        is_active,
        name,
        desc,
        tag_list,
        item_level_1,
        ...otherFilterModel
      } = filterModel;

      const formatFilter = {
        ...otherFilterModel,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        item_sku: (name || desc || type || tag_list || item_level_1) && {
          filterType: "object",
          filter: {
            item: {
              filterType: "object",
              filter: {
                name: name && name,
                desc: desc && desc,
                type: type && type,
                tag_list: tag_list && {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filterType: "set",
                      values: tag_list.values,
                    },
                  },
                },
                item_level_1: item_level_1 && {
                  filterType: "object",
                  filter: {
                    name: item_level_1,
                  },
                },
              },
            },
          },
        },
      };

      try {
        const { itemSkuDetailsFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: itemSkuDetailsFindManyAggrid.data as any[],
          rowCount: itemSkuDetailsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instanceStatus = params.api.getFilterInstance("is_active");
    const typeInstance = params.api.getFilterInstance("type");
    typeInstance?.setModel({
      values: noService
        ? [ItemType.Normal, ItemType.Variant]
        : [ItemType.Normal, ItemType.Variant, ItemType.Service],
    });
    instanceStatus?.setModel({ values: ["1"] });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.sku_name;
  }, []);

  return (
    <CheckboxModalTable
      modalTitle={"สินค้า"}
      btnTitle={t("button.add")}
      gridRef={selectItemGridref}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishItemsSelect}
      modalIsOpen={showSelectItem}
      getRowId={getRowId}
      closeModal={closeItemTable}
      selectedIds={itemIds}
      setSelectedIds={setItemIds}
      idsSnapshot={itemIdsSnapshot}
      setIdsSnapshot={setItemIdsSnapshot}
      isLoading={isLoading}
      onGridReady={onGridReady}
      rowMultiSelectWithClick={rowSelection === "multiple"}
    />
  );
};

export default CheckedItemModal;
