import dayjs from "dayjs";
import {} from "../../generated/purchase";
import { QuotationQuery, SalesDocumentType } from "../../generated/sales";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { ISalesItemList } from "../../types/Sales";
import { IQuotation } from "../../types/Sales/quotation";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

export const quotationCreatePayloadFormatter = (
  data: IQuotation,
  status: string
) => {
  const {
    id,
    item_list,
    customer_contact,
    tag_list,
    created_date,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.Quotation,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...customer } = customer_contact;

  const formatPayload = {
    ...otherData,
    customer_contact: customer,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const quotationUpdatePayloadFormatter = async (
  data: IQuotation,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    customer_contact,
    updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_contact;

  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.Quotation,
    reference_unique_id: data.unique_id,
  }));

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "quotation",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        attachment_list.push(formatAttachment);
      } else {
        attachment_list.push(file);
      }
    }
  }

  const formatPayload = {
    ...otherData,
    customer_contact: customer,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    attachment_list,
  };
  return formatPayload;
};

export const quotationQueryFormatter = async (data: IQuotation) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
    });
  });

  const formatCustomer = {
    ...data.customer_contact,
    unique_id_name: `${data.customer_contact_unique_id} - ${data.customer_contact.name}`,
  };

  const formatPayload = {
    ...data,
    customer_contact: formatCustomer,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copyQuotationFormatter = (data?: QuotationQuery["quotation"]) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      created_by,
      external_reference_id,
      accepted_date,
      accepted_remark,
      attachment_list,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      reference_unique_id_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(({ ...otherItemList }) => ({
        ...otherItemList,
      }));

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
      accepted_date: dayjs().toDate(),
      accepted_remark: "",
      attachment_list: [],
    };
  }
};

export const createSalesOrderFromQuotation = (data: IQuotation) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      external_reference_id,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      accepted_date,
      accepted_remark,
      attachment_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({ reference_document_type, reference_unique_id, ...otherItemList }) =>
          otherItemList
      );

    return {
      ...otherData,
      reference_id_list: [data.id],
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};
