import { ReactNode, useEffect, useMemo } from "react";
import {
  Control,
  Controller,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { useNavbar } from "../../../hooks/use-navbar";

import BusinessIcon from "@mui/icons-material/Business";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CustomizedButton from "../../Custom/CustomizedButton";

import { CompanyQuery, useCompanyQuery } from "../../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

interface Props {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  reset?: UseFormReset<any>;
  documentName: string;
  documentType?: string;
  documentId?: string;
  children?: ReactNode;
  handlePrint: () => void;
}

const PDFWrapper = ({
  control,
  setValue,
  reset,
  documentName,
  documentType,
  documentId,
  children,
  handlePrint,
}: Props) => {
  const navbar = useNavbar();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const tenantId = sessionStorage.getItem("tenant-id");

  const graphQLClientWithHeader =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isSuccess } = useCompanyQuery<CompanyQuery>(
    graphQLClientWithHeader,
    {
      uniqueInput: {
        unique_id: tenantId,
      },
    }
  );

  const companyAddress =
    data?.company &&
    data.company.address_list &&
    data.company.address_list.find((address) => address.is_default);

  const newBranches = useMemo(
    () => [
      {
        ...companyAddress,
        name: "สำนักงานใหญ่",
        id: -1,
      },
      {
        ...companyAddress,
        name: "ไม่ระบุ",
        id: -2,
      },
      ...(data?.company?.branch_list?.sort((a, b) => {
        const idA = a?.unique_id ?? "";
        const idB = b?.unique_id ?? "";

        return idA.localeCompare(idB, undefined, { numeric: true });
      }) || []),
    ],
    [companyAddress, data?.company?.branch_list]
  );

  useEffect(() => {
    if (isSuccess) {
      if (!reset) return;
      reset({
        id: -1,
        value: newBranches[0] || {},
      });
    }
  }, [reset, isSuccess, newBranches]);

  return (
    <>
      <Box
        sx={(theme) => ({
          position: isMobile ? "block" : "fixed",
          top: "65px",
          width: "100%",
          zIndex: 1100,
          marginLeft: "-1.5rem",
          padding: "1.5rem 1.5rem 1rem 1.5rem",
          backgroundColor: "white",
          ...(navbar?.sidebar.isSidebarOpen && {
            width: `calc(100% - 259px)`,
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        })}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography variant="h5" component="span">
              {documentName}
            </Typography>
            <Typography ml={1} component="span">
              {documentId ?? ""}
            </Typography>
          </Box>
          {!isMobile && (
            <Box display={"flex"} gap={4}>
              {control && (
                <Controller
                  control={control}
                  name={"id"}
                  render={({ field }) => (
                    <FormControl
                      {...field}
                      size="small"
                      sx={{ minWidth: "250px" }}
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        {...field}
                        size="small"
                        autoWidth
                        inputProps={{
                          renderValue: () => {
                            if (newBranches.length === 0) return;
                            const findBranch = newBranches.find(
                              (branch: any) => branch.id === field.value
                            );
                            if (!findBranch) return "";
                            if (findBranch.name?.length === 0)
                              return findBranch.unique_id;
                            else return findBranch.name;
                          },
                        }}
                        onChange={(e) => {
                          if (!e) return;
                          field.onChange(e);
                          if (!setValue) return;
                          const findBranch = newBranches.find(
                            (branch: any) => branch.id === e.target.value
                          );
                          setValue("value", findBranch);
                        }}
                      >
                        {newBranches.length > 0 &&
                          newBranches.map((option: any, index: number) => {
                            const color = "#2167D3";
                            const colorSecondaly = "#737373";
                            if (!option) return <></>;
                            return (
                              <MenuItem
                                key={option.id}
                                value={option.id}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  maxWidth: "250px",
                                }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={2}
                                    color={color}
                                    sx={{ maxHeight: "20px" }}
                                  >
                                    {index === 0 ? (
                                      <BusinessIcon
                                        sx={{ maxHeight: "20px" }}
                                      />
                                    ) : (
                                      <StorefrontIcon
                                        sx={{ maxHeight: "20px" }}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography
                                      color={color}
                                      fontWeight={600}
                                      fontSize="14px"
                                    >
                                      {data?.company?.name}
                                    </Typography>
                                  </Grid>
                                  {option.name !== "ไม่ระบุ" && (
                                    <>
                                      <Grid item xs={2}></Grid>
                                      <Grid item xs={10}>
                                        <Typography
                                          fontWeight={600}
                                          fontSize="14px"
                                        >
                                          {option.name === "สำนักงานใหญ่"
                                            ? "สำนักงานใหญ่"
                                            : `${option.unique_id}${
                                                option.name?.length > 0
                                                  ? " - " + option.name
                                                  : ""
                                              }`}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={2}></Grid>
                                  <Grid item xs={10} color={colorSecondaly}>
                                    <Typography
                                      fontSize="12px"
                                      overflow="hidden"
                                    >
                                      {[
                                        option?.address,
                                        option?.sub_district,
                                        option?.district,
                                        option?.province,
                                        option?.postal_code,
                                      ]
                                        .filter(Boolean) // Removes any undefined or empty values
                                        .join(" ")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
              <Button
                size="small"
                variant="outlined"
                onClick={handlePrint}
                fullWidth
              >
                พิมพ์เอกสาร
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "5rem" }} />
      {children}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: 1100,
            backgroundColor: "white",
            padding: "0.75rem 0",
            margin: "0 -1.5rem",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ width: "150px" }}>
            <CustomizedButton
              title="พิมพ์เอกสาร"
              fullWidth
              variant="outlined"
              onClick={handlePrint}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PDFWrapper;
