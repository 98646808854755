import {
  IServerSideGetRowsParams,
  GridReadyEvent,
  ColGroupDef,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { RefObject } from "@fullcalendar/core/preact";
import { GET_MOVEMENT_SUMMARY_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
  onFilterChanged: (params: any) => void;
}

const MovementSummaryReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColGroupDef[] = [
    {
      headerName: "",
      children: [
        {
          field: "created_date",
          headerName: t("inventory.stockEntry.createdDate"),
          filter: "agDateColumnFilter",
          hide: true,
        },
        {
          field: "source_warehouse_unique_id",
          headerName: t("inventory.warehouse"),
          filter: "agTextColumnFilter",
          hide: true,
        },
        {
          field: "item_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",
          pinned: "left",
        },
        {
          field: "item_name",
          headerName: t("inventory.items.name"),
          filter: "agTextColumnFilter",
          pinned: "left",
        },
      ],
    },
    {
      headerName: "รายการเพิ่ม",
      children: [
        {
          field: "import_purchase_order",
          headerName: "นำเข้า(ซื้อ)",
          filter: false,
        },
        {
          field: "import_manufacture_order",
          headerName: "นำเข้า(ผลิต)",
          filter: false,
        },
        { field: "import_other", headerName: "นำเข้า(อื่นๆ)", filter: false },
        { field: "import_sales_return", headerName: "รับคืน", filter: false },
        { field: "import_adjust", headerName: "ปรับเพิ่ม", filter: false },
        { field: "import_transfer", headerName: "โอนย้ายเข้า", filter: false },
        { field: "import_sum", headerName: "รวมยอดเข้า", filter: false },
      ],
    },
    {
      headerName: "รายการลด",
      children: [
        {
          field: "export_purchase_order",
          headerName: "นำออก(ขาย)",
          filter: false,
        },
        {
          field: "export_manufacture_order",
          headerName: "นำออก(ผลิต)",
          filter: false,
        },
        { field: "export_other", headerName: "นำออก(อื่นๆ)", filter: false },
        {
          field: "export_purchase_return",
          headerName: "ส่งคืน",
          filter: false,
        },
        { field: "export_adjust", headerName: "ปรับลด", filter: false },
        { field: "export_transfer", headerName: "โอนย้ายออก", filter: false },
        { field: "export_sum", headerName: "รวมยอดออก", filter: false },
      ],
    },
    {
      headerName: "สรุป",
      children: [{ field: "sum", headerName: "รวมเพิ่ม/ลด", filter: false }],
    },
    {
      headerName: "ข้อมูลสินค้า",
      children: [
        { field: "available_qty", headerName: "คงเหลือสุทธิ", filter: false },
        { field: "stock_qty", headerName: "คงคลัง", filter: false },
        {
          field: "purchase_ordered_qty",
          headerName: "กำลังจัดซื้อ",
          filter: false,
        },
        {
          field: "manufacture_ordered_qty",
          headerName: "กำลังผลิต",
          filter: false,
        },
        {
          field: "sale_committed_qty",
          headerName: "ถูกจอง(ขาย)",
          filter: false,
        },
        {
          field: "manufacture_committed_qty",
          headerName: "ถูกจอง(ผลิต)",
          filter: false,
        },
      ],
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
      };
      try {
        const { MovementSummaryReportViews } = await graphQLClient.request(
          GET_MOVEMENT_SUMMARY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: MovementSummaryReportViews.data as any[],
          rowCount: MovementSummaryReportViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default MovementSummaryReportTable;
