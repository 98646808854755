import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import { Theme } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import { usePurchaseSummary } from "../../../hooks/Purchase/use-summary";
import ControlledSelect from "../../Controller/ControlledSelect";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../generated/purchase";
import { UseFormResetField, useWatch } from "react-hook-form";
import { IPurchaseItemList } from "../../../types/Purchase";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Props {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  disabled: IDefaultForm["disabled"];
  documentType: string;
  resetField?: UseFormResetField<any>;
}

interface ISummary {
  name?: string;
  label: string;
  amount: number;
  sub_items?: ISummary[];
}

const additionalDiscountTypeOptions: ISelectOption[] = [
  {
    label: "บาท",
    value: AdditionalDiscountType.Baht,
  },
  {
    label: "%",
    value: AdditionalDiscountType.Percent,
  },
];

const PurchaseSummary = ({
  control,
  setValue,
  disabled,
  resetField,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const additionDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const priceVatType = useWatch({
    control,
    name: "price_vat_type",
  });

  const item_list = useWatch({
    control,
    name: "item_list",
  });

  const { t } = useTranslation();
  const summary = usePurchaseSummary(control, setValue);

  const netAmount =
    roundingNumber(summary.vat_exempted_amount) +
    roundingNumber(summary.vat_0_amount) +
    roundingNumber(summary.vat_7_amount) +
    roundingNumber(summary.vat_amount);

  const totalAmount =
    netAmount +
    summary.shipping_cost -
    roundingNumber(summary.withholding_tax_amount);

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "additional_discount",
        label: `${t("summary.additional_discount")}  (${
          priceVatType === PriceVatType.ExcludedVat ? "แยกภาษี" : "รวมภาษี"
        })`,
        amount: summary.additional_discount,
      },
      {
        name: "sub_total",
        label: `${t("summary.sub_total")}`,
        amount: summary.sub_total,
        sub_items: [
          {
            name: "vat_exempted_amount",
            label: `${t("summary.vat_exempted_amount")}`,
            amount: summary.vat_exempted_amount,
          },
          {
            name: "vat_0_amount",
            label: `${t("summary.vat_0_amount")}`,
            amount: summary.vat_0_amount,
          },
          {
            name: "vat_7_amount",
            label: `${t("summary.vat_7_amount")}`,
            amount: summary.vat_7_amount,
          },
        ],
      },
      {
        label: `${t("summary.vat_amount")}`,
        amount: summary.vat_amount,
      },
      {
        name: "net_amount",
        label: `${t("summary.net_amount")}`,
        amount: netAmount,
      },
      {
        label: `${t("summary.withholding_tax_amount")}`,
        amount: summary.withholding_tax_amount,
      },
      {
        name: "shipping_cost",
        label: `${t("summary.shipping_cost")}`,
        amount: summary.shipping_cost,
      },
      {
        label: `${t("summary.total_amount")}`,
        amount: totalAmount,
      },
    ],
    [
      t,
      priceVatType,
      summary.additional_discount,
      summary.sub_total,
      summary.vat_exempted_amount,
      summary.vat_0_amount,
      summary.vat_7_amount,
      summary.vat_amount,
      summary.withholding_tax_amount,
      summary.shipping_cost,
      netAmount,
      totalAmount,
    ]
  );

  const [summaryList, setSummaryList] =
    useState<ISummary[]>(defaultsSummaryList);

  useEffect(() => {
    if (additionDiscountType === AdditionalDiscountType.Percent) {
      setSummaryList([
        {
          name: "additional_discount",
          label: `${t("summary.additional_discount")}  (${
            priceVatType === PriceVatType.ExcludedVat ? "แยกภาษี" : "รวมภาษี"
          })`,
          amount: summary.additional_discount,
        },
        {
          label: "",
          amount: summary.additional_discount,
        },
        {
          name: "sub_total",
          label: `${t("summary.sub_total")}`,
          amount: summary.sub_total,
          sub_items: [
            {
              name: "vat_exempted_amount",
              label: `${t("summary.vat_exempted_amount")}`,
              amount: summary.vat_exempted_amount,
            },
            {
              name: "vat_0_amount",
              label: `${t("summary.vat_0_amount")}`,
              amount: summary.vat_0_amount,
            },
            {
              name: "vat_7_amount",
              label: `${t("summary.vat_7_amount")}`,
              amount: summary.vat_7_amount,
            },
          ],
        },
        {
          label: `${t("summary.vat_amount")}`,
          amount: summary.vat_amount,
        },
        {
          name: "net_amount",
          label: `${t("summary.net_amount")}`,
          amount: netAmount,
        },
        {
          label: `${t("summary.withholding_tax_amount")} `,
          amount: summary.withholding_tax_amount,
        },
        {
          name: "shipping_cost",
          label: `${t("summary.shipping_cost")} `,
          amount: summary.shipping_cost,
        },
        {
          label: `${t("summary.total_amount")} `,
          amount: totalAmount,
        },
      ]);
    } else {
      setSummaryList(defaultsSummaryList);
    }
  }, [
    additionDiscountType,
    defaultsSummaryList,
    netAmount,
    pre_vat_amount,
    summary.additional_discount,
    summary.shipping_cost,
    summary.sub_total,
    totalAmount,
    summary.vat_0_amount,
    summary.vat_7_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    summary.withholding_tax_amount,
    t,
    priceVatType,
  ]);

  const additionalDiscountType: AdditionalDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  return (
    <>
      {summaryList.map((item, index) =>
        ["sub_total"].includes(item.name || "") ? (
          <Accordion
            elevation={0}
            disableGutters
            key={item.label}
            sx={{ "&:before": { height: "0px" } }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon color={"primary"} />}
              sx={{
                marginRight: "-24px",
                minHeight: "40px",
                marginBottom: "8px",
                backgroundColor: "#F9F9F9",
              }}
            >
              <Typography
                color={"primary.main"}
                textAlign={isMobile ? "left" : "right"}
                flex={2}
                fontWeight={400}
              >
                {item.label}
              </Typography>
              <Typography
                color={"primary.main"}
                fontWeight={400}
                sx={{
                  minWidth: 150,
                  textAlign: "right",
                  ml: 3,
                  mr: 4.7,
                  flex: 1,
                }}
                gap={3}
              >
                {formatNumber(item.amount)}
              </Typography>
              <Typography
                color={"primary.main"}
                sx={{ ml: 2.5, mr: 2.7 }}
                fontWeight={400}
              >
                บาท
              </Typography>
            </AccordionSummary>

            {item.sub_items?.map((sub_item) => (
              <AccordionDetails
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  padding: "0px 0px 16px",
                }}
              >
                <Typography
                  color={"primary.main"}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                  fontWeight={400}
                >
                  {sub_item.label}
                </Typography>
                <Typography
                  color={"primary.main"}
                  fontWeight={400}
                  sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
                >
                  {formatNumber(sub_item.amount)}
                </Typography>
                <Typography
                  color={"primary.main"}
                  sx={{ ml: 2.5, mr: 4.7 }}
                  fontWeight={400}
                >
                  บาท
                </Typography>
              </AccordionDetails>
            ))}
          </Accordion>
        ) : (
          <Box key={item.label} sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1.5,
                gap: 3,
                py: item.name === "net_amount" ? 1 : 0,
                width:
                  item.name === "net_amount" ? "calc(100% + 24px)" : undefined,
                paddingRight: item.name === "net_amount" ? "24px" : undefined,
              }}
              bgcolor={item.name === "net_amount" ? "primary.light" : undefined}
            >
              {!["shipping_cost", "additional_discount"].includes(
                item?.name || ""
              ) || disabled ? (
                <>
                  <Typography
                    color={"primary.main"}
                    textAlign={isMobile ? "left" : "right"}
                    flex={2}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    color={"primary.main"}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                    sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
                  >
                    {formatNumber(item.amount)}
                  </Typography>
                  {additionalDiscountType === AdditionalDiscountType.Baht ? (
                    <Typography
                      color={"primary.main"}
                      sx={{ ml: 2.5, mr: 4.7 }}
                      fontWeight={item.name === "net_amount" ? 600 : 400}
                    >
                      บาท
                    </Typography>
                  ) : item.name === "additional_discount" ? (
                    <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 6 }}>
                      %
                    </Typography>
                  ) : (
                    <Typography
                      color={"primary.main"}
                      sx={{ ml: 2.5, mr: 4.7 }}
                      fontWeight={item.name === "net_amount" ? 600 : 400}
                    >
                      บาท
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    color={"primary.main"}
                    mr={2.5}
                    textAlign={isMobile ? "left" : "right"}
                    flex={2}
                  >
                    {item.label}
                  </Typography>
                  <Box
                    minWidth={150}
                    flex={1}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <ControlledNumberTextField
                      name={item?.name || ""}
                      control={control}
                      textAlign="right"
                      helperText={
                        item.name === "additional_discount" &&
                        priceVatType === PriceVatType.IncludedVat &&
                        `ก่อนภาษี ${formatNumber(
                          item_list.reduce(
                            (acc: number, item: IPurchaseItemList) => {
                              if (item.vat_percentage === "7") {
                                acc +=
                                  summary.additional_discount *
                                  ((item.pre_vat_amount / pre_vat_amount || 0) /
                                    1.07);
                              } else acc += summary.additional_discount;
                              return acc;
                            },
                            0
                          )
                        )} บาท`
                      }
                      helperTextAlign="left"
                      sx={{
                        paddingTop:
                          priceVatType === PriceVatType.IncludedVat
                            ? "21px"
                            : null,
                      }}
                    />
                  </Box>
                  {item.name === "additional_discount" ? (
                    <Box
                      sx={{
                        width: 75,
                      }}
                    >
                      <ControlledSelect
                        control={control}
                        name="additional_discount_type"
                        options={additionalDiscountTypeOptions}
                        onChange={() => {
                          if (resetField)
                            resetField("additional_discount", {
                              defaultValue: 0,
                            });
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography
                      color={"primary.main"}
                      sx={{ ml: 2.5, mr: 4.7 }}
                    >
                      บาท
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default PurchaseSummary;
