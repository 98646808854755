import { gql } from "graphql-request";

export const ITEMS_AGGRID = gql`
  query ItemsFindManyAggrid($aggridInput: AggridTemplateInput) {
    itemsFindManyAggrid(aggridInput: $aggridInput) {
      data {
        id
        unique_id
        name
        desc
        img_url
        type
        item_level_1_unique_id
        item_level_1 {
          id
          unique_id
          name
        }
        item_level_2_unique_id
        item_level_2 {
          id
          unique_id
          name
        }
        item_level_3_unique_id
        item_level_3 {
          id
          unique_id
          name
        }
        is_purchasable
        is_saleable
        is_stockable
        is_manufactured
        barcode
        is_active
        remark_status
        manufacture_minimum_qty
        package_attribute {
          width_uom_unique_id
          length_uom_unique_id
          thick_uom_unique_id
          weight_uom_unique_id
          height_uom_unique_id
          volume_uom_unique_id
          width_uom {
            name
            unique_id
          }
          length_uom {
            name
            unique_id
          }
          thick_uom {
            name
            unique_id
          }
          weight_uom {
            name
            unique_id
          }
          height_uom {
            name
            unique_id
          }
          volume_uom {
            name
            unique_id
          }
          width
          length
          thick
          weight
          height
          volume
        }
        physical_attribute {
          width_uom_unique_id
          length_uom_unique_id
          thick_uom_unique_id
          weight_uom_unique_id
          height_uom_unique_id
          volume_uom_unique_id
          width_uom {
            name
            unique_id
          }
          length_uom {
            name
            unique_id
          }
          thick_uom {
            name
            unique_id
          }
          weight_uom {
            name
            unique_id
          }
          height_uom {
            name
            unique_id
          }
          volume_uom {
            name
            unique_id
          }
          width
          length
          thick
          weight
          height
          volume
        }
        purchase_standard_price
        purchase_minimum_qty
        purchase_vat_type
        sale_price
        special_price
        sale_vat_type
        uom_group_unique_id
        uom_group {
          unique_id
          name
          description
          base_uom_unique_id
          base_uom {
            unique_id
            name
          }
          convertable_uom_list {
            unique_id
            name
          }
          uom_conversion_list {
            uom_group_unique_id
            base_uom_rate
            target_uom_unique_id
            target_uom {
              unique_id
              name
            }
            target_uom_rate
          }
        }
        stock_uom_unique_id
        purchase_uom_unique_id
        sales_uom_unique_id
        deliver_uom_unique_id
        stock_uom {
          unique_id
          name
        }
        purchase_uom {
          unique_id
          name
        }
        sales_uom {
          unique_id
          name
        }
        deliver_uom {
          unique_id
          name
        }
        tracability
        sku_list {
          sku_name
          item_id
          item_variant_value {
            item_variant_value_name
            item_variant_key_name
          }
          sku_detail {
            id
            sku_name
            barcode
            seller_sku_name
            sale_price
            other_cost
            cost_price
            special_price
            is_active
            img_url
            item_sku_qty {
              id
              warehouse_level_3 {
                sub_level_2 {
                  sub_level_1 {
                    warehouse_unique_id
                  }
                }
              }
              bin_location {
                id
                bin_name
              }
              item_unique_id
              sku_name
              stock_qty
              available_qty
              purchase_ordered_qty
              manufacture_ordered_qty
              sale_committed_qty
              manufacture_committed_qty
            }
          }
        }
        item_variant_key_list {
          item_variant_key_name
          item_variant_value_list {
            item_variant_value_name
          }
        }
        minimum_stock
        maximum_stock
        reorder_point
        bundle_item_detail_list {
          id
          item_unique_id
          item {
            sales_uom_unique_id
            sales_uom {
              name
            }
            purchase_standard_price
          }
          reference_item_unique_id
          reference_item {
            name
            desc
            purchase_standard_price
            sales_uom_unique_id
            sales_uom {
              name
            }
          }
          item_sku_name
          qty
        }
      }
      count
    }
  }
`;

export const ITEMS_VIEW = gql`
  query ItemViews($aggridInput: AggridTemplateInput) {
    itemViews(aggridInput: $aggridInput) {
      count
      data {
        item_id
        sku_name
        img_url
        name
        desc
        type
        seller_sku_name
        barcode
        stock_qty
        available_qty
        tag_list
        item_level_1_name
        is_active
        created_date
        created_by_object
        created_by
      }
    }
  }
`;

export const ITEMS_COUNT_AGGRID = gql`
  query ItemsFindManyAggrid($aggridInput: AggridTemplateInput) {
    itemsFindManyAggrid(aggridInput: $aggridInput) {
      count
    }
  }
`;

export const ITEM_STOCK_UOMS = gql`
  query ItemStockUoms($itemUniqueIdList: [String!]!) {
    itemStockUoms(itemUniqueIdList: $itemUniqueIdList) {
      unique_id
      stock_uom {
        unique_id
        name
        id
      }
    }
  }
`;

export const ITEMS_SKU_AGGRID = gql`
  query ItemSkuDetailsFindManyAggrid($aggridInput: AggridTemplateInput) {
    itemSkuDetailsFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        img_url
        sku_name
        special_price
        other_cost
        item_sku {
          item {
            unique_id
            name
            barcode
            type
            tracability
            stock_uom_unique_id
            stock_uom {
              unique_id
              name
            }
            img_url
            purchase_standard_price
            sales_uom_unique_id
            sales_uom {
              unique_id
              name
            }
            desc
            item_level_1 {
              name
            }
            tag_list {
              name
            }
            is_active
            uom_group {
              base_uom {
                name
                unique_id
              }
              convertable_uom_list {
                name
                unique_id
              }
              uom_conversion_list {
                id
                base_uom_rate
                target_uom_unique_id
                target_uom {
                  name
                  unique_id
                }
                target_uom_rate
              }
            }
            physical_attribute {
              volume
              weight
            }
            bom_id
            bom_detail
            routing_id
            routing_detail
            manufacture_minimum_qty
            sale_vat_type
            sale_price
            purchase_vat_type
          }
        }
        is_active
        item_sku_qty {
          warehouse_level_3 {
            sub_level_3_name
            sub_level_2_id
            sub_level_2 {
              sub_level_2_name
              sub_level_1_id
              sub_level_1 {
                sub_level_1_name
                warehouse_unique_id
                warehouse {
                  unique_id
                  name
                }
              }
            }
          }
          sub_level_3_id
          purchase_ordered_qty
          manufacture_ordered_qty
          sale_committed_qty
          manufacture_committed_qty
          stock_qty
          available_qty
        }
        stock_qty
        available_qty
        purchase_ordered_qty
        manufacture_ordered_qty
        sale_committed_qty
        manufacture_committed_qty
        barcode
      }
    }
  }
`;

export const ITEM_SKU_QTY_BY_WAREHOUSE_VIEW = gql`
  query ItemSkuQtysByWarehouseView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByWarehouseView(aggridInput: $aggridInput) {
      count
      data {
        item_id
        item_unique_id
        sku_name
        item_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
        manufacture_committed_qty
      }
    }
  }
`;

export const ITEM_SKU_QTY_BY_BIN_LOCATION_VIEW = gql`
  query ItemSkuQtysByBinLocationView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByBinLocationView(aggridInput: $aggridInput) {
      count
      data {
        item_id
        item_unique_id
        sku_name
        item_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_id
        bin_name
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
        manufacture_committed_qty
      }
    }
  }
`;

export const ITEM_SKU_QTY_BY_BARCODE_VIEW = gql`
  query ItemSkuQtysByBarcodeView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByBarcodeView(aggridInput: $aggridInput) {
      count
      data {
        item_id
        item_unique_id
        sku_name
        item_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_id
        bin_name
        serial_no
        barcode
        lot_date
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
        manufacture_committed_qty
      }
    }
  }
`;

export const GOODS_RECEIVES_AGGRID = gql`
  query GoodsReceivesFindManyAggrid($aggridInput: AggridTemplateInput) {
    goodsReceivesFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        reference_unique_id
        created_date
        posted_date
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        source_warehouse_unique_id
        source_warehouse {
          name
          unique_id
        }
        destination_warehouse_unique_id
        destination_warehouse {
          name
          unique_id
        }
        type
        aggrid_status
        main_status
        sub_status
        flag_status
        remark
        trace_entry_list {
          unique_id
          type
          item_unique_id
          item_name
          qty
          uom_unique_id
          uom {
            id
            name
            unique_id
          }
          cost_price
          discount
          additional_cost
          remark
          created_date
          posted_date
          lot_date
          source_bin_location_id
          source_bin_location {
            bin_name
          }
          destination_bin_location_id
          destination_bin_location {
            bin_name
          }
          tracability
          barcode
          status
        }
      }
    }
  }
`;

export const GOODS_ISSUES_AGGRID = gql`
  query GoodsIssuesFindManyAggrid($aggridInput: AggridTemplateInput) {
    goodsIssuesFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        reference_unique_id
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        posted_date
        trace_entry_list {
          unique_id
          type
          item_unique_id
          item_name
          qty
          uom_unique_id
          uom {
            id
            name
            unique_id
          }
          cost_price
          discount
          additional_cost
          remark
          created_date
          posted_date
          lot_date
          source_bin_location_id
          source_bin_location {
            bin_name
          }
          destination_bin_location_id
          destination_bin_location {
            bin_name
          }
          tracability
          barcode
          status
        }
        source_warehouse_unique_id
        source_warehouse {
          name
          unique_id
        }
        destination_warehouse {
          name
          unique_id
        }
        type
        aggrid_status
        main_status
        sub_status
        flag_status
        remark
      }
    }
  }
`;

export const GOODS_TRANSFERS_AGGRID = gql`
  query GoodsTransfersFindManyAggrid($aggridInput: AggridTemplateInput) {
    goodsTransfersFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        posted_date
        trace_entry_list {
          unique_id
          type
          item_unique_id
          item_name
          qty
          uom_unique_id
          uom {
            id
            name
            unique_id
          }
          cost_price
          discount
          additional_cost
          remark
          created_date
          posted_date
          lot_date
          source_bin_location_id
          source_bin_location {
            bin_name
          }
          destination_bin_location_id
          destination_bin_location {
            bin_name
          }
          tracability
          barcode
          status
        }
        source_warehouse_unique_id
        source_warehouse {
          name
          unique_id
        }
        destination_warehouse_unique_id
        destination_warehouse {
          name
          unique_id
        }
        aggrid_status
        main_status
        sub_status
        flag_status
        remark
      }
    }
  }
`;

export const GOODS_ADJUSTMENTS_AGGRID = gql`
  query GoodsAdjustsFindManyAggrid($aggridInput: AggridTemplateInput) {
    goodsAdjustsFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        posted_date
        trace_entry_list {
          unique_id
          type
          item_unique_id
          item_name
          qty
          uom_unique_id
          uom {
            id
            name
            unique_id
          }
          cost_price
          discount
          additional_cost
          remark
          created_date
          posted_date
          lot_date
          source_bin_location_id
          source_bin_location {
            bin_name
          }
          destination_bin_location_id
          destination_bin_location {
            bin_name
          }
          tracability
          barcode
          status
        }
        source_warehouse_unique_id
        source_warehouse {
          unique_id
          name
        }
        destination_warehouse_unique_id
        destination_warehouse {
          unique_id
          name
        }
        aggrid_status
        main_status
        sub_status
        flag_status
        remark
      }
    }
  }
`;

export const GET_ALL_WAREHOUSES = gql`
  query Warehouses {
    warehouses {
      unique_id
      name
      type
      description
      is_active
      warehouse_level_1_list {
        sub_level_1_name
        warehouse_unique_id
        sub_level_2_list {
          sub_level_2_name
          sub_level_1_id
          sub_level_3_list {
            sub_level_3_name
            sub_level_2_id
            bin_location_list {
              bin_name
              sub_level_3_id
            }
          }
        }
      }
    }
  }
`;

export const GET_GROUP_LEVEL_ONE = gql`
  query ItemGroupLevel1s {
    itemGroupLevel1s {
      unique_id
      name
    }
  }
`;

export const GET_GROUP_LEVEL_TWO = gql`
  query ItemGroupLevel2s {
    itemGroupLevel2s {
      unique_id
      name
    }
  }
`;

export const GET_GROUP_LEVEL_THREE = gql`
  query ItemGroupLevel3s {
    itemGroupLevel3s {
      unique_id
      name
    }
  }
`;

export const GET_ALL_BIN_LOCATION = gql`
  query WarehouseBinLocations {
    warehouseBinLocations {
      id
      bin_name
    }
  }
`;

export const GET_ALL_UOM = gql`
  query Uoms {
    uoms {
      id
      unique_id
      name
    }
  }
`;

export const TRACE_ENTRY_FIND_MANY_AGGRID = gql`
  query TraceEntriesFindManyAggrid($aggridInput: AggridTemplateInput) {
    traceEntriesFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        id
        unique_id
        reference_unique_id
        type
        item_unique_id
        item_name
        qty
        uom_unique_id
        uom {
          unique_id
          name
        }
        cost_price
        discount
        additional_cost
        remark
        created_date
        posted_date
        lot_date
        source_bin_location_id
        source_bin_location {
          id
          bin_name
          sub_level_3 {
            sub_level_3_name
            sub_level_2_id
            sub_level_2 {
              sub_level_2_name
              sub_level_1_id
              sub_level_1 {
                sub_level_1_name
                warehouse_unique_id
                warehouse {
                  unique_id
                  name
                }
              }
            }
          }
        }
        destination_bin_location_id
        destination_bin_location {
          id
          bin_name
          sub_level_3 {
            sub_level_3_name
            sub_level_2_id
            sub_level_2 {
              sub_level_2_name
              sub_level_1_id
              sub_level_1 {
                sub_level_1_name
                warehouse_unique_id
                warehouse {
                  unique_id
                  name
                }
              }
            }
          }
        }
        tracability
        barcode
        stock_qty
        serial_no
        scanned_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        is_approve
        status
        destination_scanned_by {
          user_unique_id
          first_name
          last_name
        }
        goods_receive_unique_id
        goods_issue_unique_id
        goods_transfer_unique_id
        goods_adjust_unique_id
      }
    }
  }
`;
