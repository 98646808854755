import {
  Box,
  Link,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  OutlinedInput,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import moment from "moment";
// import { useSelector } from "react-redux";
import { departmentType, positionType } from "../../../utils/userInfo";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IDefaultForm } from "../../../types/global";
import ControlledServerSideCreatable from "../../Controller/ControlledSSCreatable";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedButton from "../../Custom/CustomizedButton";
import DropzoneUI from "../../UI/DropzoneUI";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import { formatDate } from "../../../utils/Formatter/Date";
import { EntityTypeEnum } from "../../../generated/creatable";
// import ControlledServerSideCreatable from "../../Custom/ControlledSSCreatable";
import ModalUI from "../../UI/ModalUI";
import QRCode from "qrcode.react";
import { useCookies } from "react-cookie";
import { useStateContext } from "../../../contexts/auth-context";

type ExtendedProps = IDefaultForm & {
  trigger?: any;
};

// interface IPosition {
//   id: string;
//   label: string;
//   value: string;
// }

const UserAccountForm = ({
  control,
  errors,
  setValue,
  disabled,
  getValues,
}: ExtendedProps) => {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  // const { user } = useSelector((state) => state.account);
  const [enableChangePassword, setEnableChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  // const [position, setPosition] = useState<IPosition[]>(positionType);
  const [lineConnectModalOpen, setLineConnectModalOpen] =
    useState<boolean>(false);
  const [lineDisconnectModalOpen, setLineDisconnectModalOpen] =
    useState<boolean>(false);

  const [cookie] = useCookies();

  const {
    state: { authUser },
  } = useStateContext();

  const qrCodeSize = 500;

  const chipData: string[] = [
    `วันที่สร้าง: ${
      getValues("created_date") ? formatDate(getValues("created_date")) : "-"
    }`,
    `วันที่ใช้งานล่าสุด:  ${
      getValues("last_login_date")
        ? formatDate(getValues("last_login_date"))
        : "-"
    }`,
    `วันที่แก้ไขล่าสุด:  ${
      getValues("last_updated_date")
        ? formatDate(getValues("last_updated_date"))
        : "-"
    }`,
  ];

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const radioList = [
    { value: 1, label: "ใช้งาน" },
    { value: 0, label: "หยุดใช้งาน" },
  ];

  const handleClickEnableChangePassword = () => {
    setEnableChangePassword(!enableChangePassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleClickShowOldPassword = () => {
  //   setShowOldPassword(!showOldPassword);
  // };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const image = getValues("img_url");

  const unique_id = getValues("unique_id");

  return (
    <>
      <CustomizedBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              {t("user.title.information")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  key="natural_person-initial"
                  name="title_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedSelect
                      fullWidth
                      error={Boolean(errors.title_name)}
                      helperText={errors.title_name?.message}
                      label={t("user.account.title_name")}
                      options={prefixes}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="nick_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.nick_name)}
                      helperText={errors.nick_name?.message}
                      label={t("user.account.nick_name")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name?.message}
                      label={t("user.account.first_name")}
                      {...field}
                      disabled={disabled}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.last_name)}
                      helperText={errors.last_name?.message}
                      label={t("user.account.last_name")}
                      {...field}
                      disabled={disabled}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" marginY={2} marginLeft={1}>
              {t("user.title.contact")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      label={t("user.account.email")}
                      {...field}
                      disabled={disabled}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                      label={t("user.account.phone")}
                      {...field}
                      disabled={disabled}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("user.title.organic")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  title={t("user.account.department")}
                  control={control}
                  name="department"
                  documentType={EntityTypeEnum.Employee}
                  defaultOptions={departmentType}
                  error={Boolean(errors.department)}
                  helperText={errors.department?.message}
                  setValue={setValue}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  title={t("user.account.position")}
                  control={control}
                  name="position"
                  documentType={EntityTypeEnum.Employee}
                  defaultOptions={positionType}
                  error={Boolean(errors.position)}
                  helperText={errors.position?.message}
                  setValue={setValue}
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
            {!id && (
              <>
                <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                  {t("user.title.set_password")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                      name="new_password"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          disabled={disabled}
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          <InputLabel htmlFor="outlined-adornment-password1">
                            {t("user.account.password")}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password1"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  disabled={disabled}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("user.account.password")}
                            {...field}
                          />
                          <FormHelperText
                            id="helper-text-password1"
                            sx={{ whiteSpace: "pre-wrap" }}
                          >
                            {errors.new_password?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                      name="confirm_new_password"
                      control={control}
                      render={({ field }) => (
                        <CustomizedTextField
                          type="password"
                          fullWidth
                          error={Boolean(errors.confirm_new_password)}
                          helperText={errors.confirm_new_password?.message}
                          label={t("user.account.confirm_password")}
                          disabled={disabled}
                          required
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {id && (
              <>
                {!disabled ? (
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                      {t("user.title.change_password")}
                    </Typography>
                    <Switch
                      checked={enableChangePassword}
                      onChange={handleClickEnableChangePassword}
                      disabled={disabled}
                    />
                  </Box>
                ) : null}
                {enableChangePassword && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                              name="old_password"
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  disabled={disabled}
                                >
                                  <InputLabel htmlFor="outlined-adornment-password3">
                                    {t("user.account.old_password")}
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password3"
                                    type={showOldPassword ? "text" : "password"}
                                    endAdornment={
                                      !disabled ? (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowOldPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {showOldPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    label={t("user.account.old_password")}
                                    {...field}
                                    autoComplete="new-password"
                                  />
                                </FormControl>
                              )}
                            />
                          </Grid> */}
                        <Box width="100%" />
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Controller
                            name="new_password"
                            control={control}
                            render={({ field }) => (
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                disabled={disabled}
                                error={Boolean(errors.new_password)}
                              >
                                <InputLabel htmlFor="outlined-adornment-password2">
                                  {t("user.account.new_password")}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password2"
                                  type={showNewPassword ? "text" : "password"}
                                  endAdornment={
                                    !disabled ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          // onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  label={t("user.account.new_password")}
                                  {...field}
                                />
                                <FormHelperText
                                  id="helper-text-password1"
                                  sx={{ whiteSpace: "pre-wrap" }}
                                >
                                  <List sx={{ listStyleType: "disc" }}>
                                    {errors.new_password?.types
                                      ? Object.keys(errors.new_password?.types)
                                          .flatMap(
                                            (key) =>
                                              errors.new_password?.types[key]
                                          )
                                          .map((message) => (
                                            <ListItem
                                              sx={{
                                                display: "list-item",
                                                py: 0.25,
                                              }}
                                            >
                                              {message}
                                            </ListItem>
                                          ))
                                      : null}
                                  </List>
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Controller
                            name="confirm_new_password"
                            control={control}
                            render={({ field }) => (
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                disabled={disabled}
                                error={Boolean(errors.confirm_new_password)}
                              >
                                <InputLabel htmlFor="outlined-adornment-password2">
                                  {t("user.account.confirm_new_password")}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password2"
                                  type={
                                    showConfirmNewPassword ? "text" : "password"
                                  }
                                  endAdornment={
                                    !disabled ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmNewPassword
                                          }
                                          // onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showConfirmNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  label={t("user.account.new_password")}
                                  {...field}
                                />
                                <FormHelperText
                                  id="helper-text-password1"
                                  sx={{ whiteSpace: "pre-wrap" }}
                                >
                                  {errors.confirm_new_password?.message}
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              สถานะ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <CustomizedRadioGroup
                      {...field}
                      radioList={radioList}
                      row
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="remark"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.remark)}
                      label={t("user.account.remark")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              {!disabled && unique_id === authUser?.unique_id && (
                <Grid item xs={12} md={12}>
                  <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                    เชื่อมต่อกับ Line
                  </Typography>
                  {!getValues("line_uid") ? (
                    <CustomizedButton
                      title="เชื่อมต่อกับ Line"
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        setLineConnectModalOpen(true);
                      }}
                    />
                  ) : (
                    <>
                      Line UID: {getValues("line_uid")}
                      <Link
                        style={{ cursor: "pointer", marginLeft: "0.75rem" }}
                        underline={"always"}
                        color="primary"
                        onClick={() => {
                          setLineDisconnectModalOpen(true);
                        }}
                      >
                        ยกเลิกการเชื่อมต่อ
                      </Link>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            {disabled && (
              <>
                <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                  ข้อมูลอื่นๆ
                </Typography>
                {chipData.map((chip: string) => (
                  <CustomizedChips
                    sx={{ mr: 1 }}
                    key={chip}
                    value={chip}
                    color="secondary"
                    variant="outlined"
                  />
                ))}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
          {((image && image.length > 0) || !disabled) && (
            <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5}>
              <Typography fontWeight="bold" sx={{ mb: 2, ml: 1 }}>
                รูปภาพ
              </Typography>
              <DropzoneUI
                control={control}
                name={"img_url"}
                setValue={setValue}
                acceptedFileType="image"
                maxSize={1}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </CustomizedBox>

      <ModalUI
        open={lineConnectModalOpen}
        handleClose={() => {
          setLineConnectModalOpen(false);
        }}
        fullWidth
        maxWidth="sm"
        title="เชื่อมต่อกับ Line"
      >
        <Box display="flex" alignItems="center" flexDirection={"column"}>
          {cookie && cookie.access_token ? (
            <QRCode value={cookie.access_token} size={qrCodeSize} />
          ) : (
            "ไม่พบข้อมูล"
          )}

          <Link
            style={{ marginTop: "2rem", cursor: "pointer" }}
            underline={"always"}
            href="https://doc.clickup.com/3654689/p/h/3fh11-53362/db286604b2ae948/3fh11-83876"
            target="_blank"
            color="primary"
          >
            วิธีการใช้งาน
          </Link>
        </Box>
      </ModalUI>

      <ModalUI
        open={lineDisconnectModalOpen}
        handleClose={() => {
          setLineDisconnectModalOpen(false);
        }}
        fullWidth
        maxWidth="sm"
        title="คุณยืนยันการยกเลิกกการเชื่อมต่อใช่หรือไม่"
        action={
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={() => {
                setLineDisconnectModalOpen(false);
              }}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={() => {
                return;
              }}
            />
          </Box>
        }
      >
        <Box display="flex" flexDirection={"column"}>
          คุณจะไม่ได้สามารถเข้าสู่ระบบด้วยไลน์ได้และจำเป็นจะต้องตั้งรหัสผ่านก่อนยกเลิกและคุณจะไม่สามารถรับการแจ้งเตือนผ่านไลน์ได้
        </Box>
      </ModalUI>
    </>
  );
};

export default UserAccountForm;
